import { getIn } from 'formik';
import Lottie from 'lottie-react-web';

import { Box, CircularProgress } from '@mui/material';

import CheckIcon from 'animation/check.json';
import ErrorIcon from 'animation/error.json';

import { LANGUAGE } from 'constants/language';
import {
  getLegalRepresentativeRef,
  getOwnerRef,
  getBeneficialOwnerRef,
  getLoanApplicantRef,
  returnBooleanForBackend,
} from 'utils/dataHandlers';
import { getSalutationLabel } from 'constants/salutations';
import { getTitleLabel } from 'constants/titles';
import {
  EKLegalForm,
  EKLegalFormLabel,
  FreelanceLegalForm,
  FreelanceLegalFormLabel,
  GbRLegalForm,
  GbRLegalFormKey,
  PartGLegalForm,
  PartGLegalFormKey,
  PartGmbBLegalForm,
  PartGmbBLegalFormKey,
} from 'constants/legalForms';
import {
  Company,
  MainCompany,
  Person,
  RefArrays,
  RefBeneficialOwner,
  RefOwner,
  RefLegalRepresentative,
  RefLoanApplicant,
  RefContext,
} from 'context/customer-context/types';
import DeletingOWBOMessage from 'components/DeletingOWBOMessage';
import { request } from 'service/ApiCaptiq';
import * as ApiCaptiq from 'service/ApiCaptiq';
import { CustomerProfileIdType } from 'context/customer-context/constants';
import { LEGAL_FORM_SLUG } from 'constants/defaults';
import {
  CustomerProfileRole,
  GeneralRole,
  LegalForm,
  LegalFormsTypes,
  SetNewFunction,
  FastFieldProps,
  RemoveRef,
  RemoveRefContext,
  YesOrNo,
  Level,
} from './commonTypes/types';
import { BackendSimpleExtended } from './backendTypes';

export const regexForValidNumberThree = /^\d{1,4}(?:\.\d{1,3})?$/;

export const validationValueNumber = (value: string | undefined, moreDecimals: boolean = false) => {
  if (LANGUAGE === 'de' && value) {
    value = value.replace(',', '.');
  }

  return moreDecimals
    ? !!value &&
        regexForValidNumberThree.test(`${value}`) &&
        Number(value) >= 0 &&
        Number(value) <= 100
    : !!value && regexForValidNumber.test(`${value}`) && Number(value) >= 0 && Number(value) <= 100;
};

export const getCurrencyValue = (value: string | number) =>
  Number(value).toLocaleString('de-DE', {
    style: 'currency',
    currency: 'EUR',
  });

export const getPercentValue = (value: string | number, decimals: number = 2) =>
  (Number(value) / 100).toLocaleString('de-DE', {
    style: 'percent',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });

export const isValueValid = (value: any) => value !== '' && value !== null && value !== undefined;

export const shouldOnlyShowPersons = (
  legalForms: LegalFormsTypes | null,
  legalForm: LegalForm | '' | undefined,
  type: GeneralRole,
) => {
  // NOTE: the structure only allows to add companies for BO and OW, the other cases are just for completeness
  if (!legalForms || !legalForm) {
    return false;
  }
  let role: CustomerProfileRole;
  switch (type) {
    case 'BO':
      role = 'BOC';
      break;
    case 'OW':
      role = 'OWC';
      break;
    case 'LA':
      role = 'LAC';
      break;
    case 'LR':
      role = 'LRP';
      break;
    default:
      throw new Error(`Type: ${type} is not a valid option`);
  }
  return !legalForms[legalForm].ranges[type].roles.includes(role);
};

export function getCompanyLabel(
  company: { companyName: string; legalForm: LegalForm | '' },
  legalForms: LegalFormsTypes | null,
) {
  return `${company.companyName} ${
    legalForms && legalForms[company.legalForm as LegalForm]
      ? legalForms[company.legalForm as LegalForm].short_label
      : company.legalForm
  }`;
}

export function getLegalForm(
  company: { companyName: string; legalForm: string },
  legalForms: LegalFormsTypes | null,
) {
  return `${
    legalForms?.[company.legalForm as LegalForm]
      ? legalForms[company.legalForm as LegalForm].short_label
      : company.legalForm
  }`;
}

export const legalFormsWithHGB = [
  'AG',
  'GmbH',
  'GmbH_&_Co_KG',
  'GmbH_&_Co_KGaA',
  'KG',
  'KGaA',
  'OHG',
  'UG',
  'UG_&_Co_KG',
  'UG_&_Co_OHG',
];

export function isPerson(pet: Person | Company | MainCompany): pet is Person {
  return (pet as Person).firstNames !== undefined;
}

export const getRolesForPersonLegalForm = (
  legalForm: string,
  allocation: string,
  sideDeal: YesOrNo,
): CustomerProfileRole[] => {
  if (legalForm === GbRLegalFormKey) {
    if (getLocaleAllocation(allocation, LANGUAGE) > 25) return ['OWP', 'BOP', 'LRP', 'LAP'];
    return ['OWP', 'LRP', 'LAP'];
  }
  if (legalForm === FreelanceLegalForm || legalForm === EKLegalForm)
    return ['OWP', 'BOP', 'LRP', 'LAP'];
  if (legalForm === PartGLegalFormKey || legalForm === PartGmbBLegalFormKey) {
    if (
      getLocaleAllocation(allocation, LANGUAGE) > 25 ||
      (getLocaleAllocation(allocation, LANGUAGE) <= 25 && sideDeal === 'yes')
    )
      return ['OWP', 'BOP', 'LRP'];
    return ['OWP', 'LRP'];
  }
  return ['LRP'];
};

export const getRoleArrayFromPersonCompany = (entity: Person | Company): CustomerProfileRole[] => {
  const roles: CustomerProfileRole[] = [];
  if (isPerson(entity)) {
    if (entity.isBeneficialOwner === 'yes') roles.push('BOP');
    if (entity.isOwner === 'yes') roles.push('OWP');
    if (entity.isLegalRepresentative === 'yes') roles.push('LRP');
    if (entity.isLoanApplicant === 'yes') roles.push('LAP');
  } else {
    if (entity.isBeneficialOwner === 'yes') roles.push('BOC');
    if (entity.isOwner === 'yes') roles.push('OWC');
    if (entity.isMainCompany === 'yes') roles.push('LAC');
  }
  return roles;
};

export const getNewRefArray = (
  refsArray: RefArrays,
  id: number,
  checkInChildren: boolean = false,
): RefArrays => {
  const newRefsArray: RefArrays = [...refsArray] as RefArrays;
  let deleteIndex = newRefsArray.findIndex((existingRef: RefContext) => existingRef.id === id);
  if (deleteIndex !== -1) {
    newRefsArray.splice(deleteIndex, 1);
  } else if (checkInChildren) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < newRefsArray.length; i++) {
      const newBOCRef = newRefsArray[i] as RefBeneficialOwner;
      if (newBOCRef.children) {
        deleteIndex = newBOCRef.children.findIndex(
          (existingRef: RefBeneficialOwner) => existingRef.id === id,
        );
        if (deleteIndex !== -1) {
          newBOCRef.children.splice(deleteIndex, 1);
          break;
        } else {
          // eslint-disable-next-line no-plusplus
          for (let j = 0; j < newBOCRef.children.length; j++) {
            const newBOCThirdRef = newBOCRef.children[j] as RefBeneficialOwner;
            if (newBOCThirdRef.children) {
              deleteIndex = newBOCThirdRef.children.findIndex(
                (existingRef: RefBeneficialOwner) => existingRef.id === id,
              );
              if (deleteIndex !== -1) {
                newBOCThirdRef.children.splice(deleteIndex, 1);
                break;
              }
            }
          }
        }
      }
    }
  } else throw new Error("The id doesn't exist on the context");
  return newRefsArray;
};

export const removeFromContext = (
  refsArray: RefArrays,
  setNewRefs: SetNewFunction,
  id: number,
  checkInChildren: boolean = false,
) => {
  const newRefsArray: RefArrays = getNewRefArray(refsArray, id, checkInChildren);
  setNewRefs(newRefsArray);
};

export const removeFromArray = (
  arrayRoles: CustomerProfileRole[],
  role: CustomerProfileRole,
): CustomerProfileRole[] => {
  const index = arrayRoles.indexOf(role);
  if (index > -1) {
    arrayRoles.splice(index, 1);
  }
  return arrayRoles;
};

export const removeFromContextArray = (
  refsArray: RefArrays,
  setNewRefs: SetNewFunction,
  groupedIDs: number[],
  checkInChildren: boolean = false,
) => {
  let newRefsArray: RefArrays = [...refsArray] as RefArrays;
  groupedIDs.forEach((currentID) => {
    newRefsArray = getNewRefArray(newRefsArray, currentID, checkInChildren);
  });
  if (groupedIDs.length !== 0) setNewRefs(newRefsArray);
};

export const groupRefsByRole = (data: RemoveRef[]) => {
  const result = data.reduce(
    (acc, currentRef) => {
      currentRef.removedRoles.forEach((role) => {
        if (role === 'BOP' || role === 'BOC') acc.BO.push(currentRef.id);
        else if (role === 'OWC' || role === 'OWP') acc.OW.push(currentRef.id);
        else acc[role as 'LAP' | 'LRP'].push(currentRef.id);
      });
      return acc;
    },
    {
      LRP: [],
      LAP: [],
      BO: [],
      OW: [],
    } as RemoveRefContext,
  );
  return result;
};

export const getLocaleAllocation = (value: string | undefined, locale: string) => {
  if (value === undefined || value === '' || value === null) return 0;
  return locale === 'de' ? Number(value.replace(',', '.')) : Number(value);
};

export const shouldFastFieldUpdate =
  (customFunction?: (nextProps: FastFieldProps, currentProps: FastFieldProps) => boolean) =>
  (nextProps: FastFieldProps, currentProps: FastFieldProps) =>
    nextProps.disabled !== currentProps.disabled ||
    nextProps.name !== currentProps.name ||
    nextProps.formik.isSubmitting !== currentProps.formik.isSubmitting ||
    Object.keys(nextProps).length !== Object.keys(currentProps).length ||
    getIn(nextProps.formik.values, currentProps.name) !==
      getIn(currentProps.formik.values, currentProps.name) ||
    getIn(nextProps.formik.errors, currentProps.name) !==
      getIn(currentProps.formik.errors, currentProps.name) ||
    getIn(nextProps.formik.touched, currentProps.name) !==
      getIn(currentProps.formik.touched, currentProps.name) ||
    (!!customFunction && customFunction(nextProps, currentProps));

export function getPersonLabel(person: Person) {
  const salutation = person.salutation ? getSalutationLabel(person.salutation) : '';
  const title = person.title ? getTitleLabel(person.title) : '';
  return `${salutation} ${title} ${person.firstNames} ${person.lastName}`.trim();
}

export const getYesOrNOLabel = (value: 'yes' | 'no' | '') => {
  switch (value) {
    case 'yes':
      return 'Ja';
    case 'no':
      return 'Nein';
    default:
      return '';
  }
};

export const getShareRoles = (allocation: string | undefined, sideDeal: YesOrNo = '') => {
  const roles = {
    isOwner: false,
    isBeneficialOwner: false,
  };
  const currentAllocation = getLocaleAllocation(allocation, LANGUAGE);
  if (currentAllocation > 0) {
    roles.isOwner = true;
    if (currentAllocation <= 25 && sideDeal === 'yes') roles.isBeneficialOwner = true;
    if (currentAllocation > 25) roles.isBeneficialOwner = true;
  }
  return roles;
};

export const getWarningMessage = (
  typeEntity: 'person' | 'company',
  contextList: Person[] | Company[],
  createdEntity: Person | Company | BackendSimpleExtended,
  allocation: string,
  sideDeal: YesOrNo,
  mainCompany: MainCompany | null,
  companyName?: string,
) => {
  if (typeEntity === 'person') {
    const people = contextList as Person[];
    const personInContext = people.find((p: Person) => p.id === createdEntity?.id);
    if (personInContext) {
      const personShares = personInContext.shares.find((s) => s.parent === mainCompany?.id);
      if (personShares) {
        const { isOwner, isBeneficialOwner } = getShareRoles(
          personShares.allocation,
          personShares.sideDeal,
        );
        const { isOwner: isCurrentOwner, isBeneficialOwner: isCurrentBeneficialOwner } =
          getShareRoles(allocation, sideDeal);
        if (isOwner && !isCurrentOwner && isBeneficialOwner && !isCurrentBeneficialOwner)
          return (
            <>
              <DeletingOWBOMessage type="OW" entityType={typeEntity} parentName={companyName} />
              <DeletingOWBOMessage type="BO" entityType={typeEntity} />
            </>
          );
        if (isOwner && !isCurrentOwner && isBeneficialOwner === isCurrentBeneficialOwner)
          return <DeletingOWBOMessage type="OW" entityType={typeEntity} parentName={companyName} />;
        if (isOwner === isCurrentOwner && isBeneficialOwner && !isCurrentBeneficialOwner)
          return <DeletingOWBOMessage type="BO" entityType={typeEntity} />;
      }
    }
  } else {
    const companies = contextList as Company[];
    const companyInContext = companies.find((p) => p.id === createdEntity?.id);
    if (companyInContext) {
      const companyShares = companyInContext.shares.find((s) => s.parent === mainCompany?.id);
      if (companyShares) {
        const { isOwner, isBeneficialOwner } = getShareRoles(
          companyShares.allocation,
          companyShares.sideDeal,
        );
        const { isOwner: isCurrentOwner, isBeneficialOwner: isCurrentBeneficialOwner } =
          getShareRoles(allocation, sideDeal);
        if (isOwner && !isCurrentOwner && isBeneficialOwner && !isCurrentBeneficialOwner) {
          return (
            <>
              <DeletingOWBOMessage type="OW" entityType={typeEntity} parentName={companyName} />
              <DeletingOWBOMessage type="BO" entityType={typeEntity} />
            </>
          );
        }
        if (isOwner && !isCurrentOwner && isBeneficialOwner === isCurrentBeneficialOwner)
          return <DeletingOWBOMessage type="OW" entityType={typeEntity} parentName={companyName} />;
        if (isOwner === isCurrentOwner && isBeneficialOwner && !isCurrentBeneficialOwner)
          return <DeletingOWBOMessage type="BO" entityType={typeEntity} />;
      }
    }
  }
  return null;
};

export const addRoleRefs = (
  role: GeneralRole,
  personIndex: number,
  people: Person[] | Company[],
  setRef: SetNewFunction,
  ref: RefArrays,
  type: 'person' | 'company' = 'person',
) => {
  switch (role) {
    case 'BO':
      setRef([
        ...ref,
        getBeneficialOwnerRef(people[personIndex], type, personIndex),
      ] as RefBeneficialOwner[]);
      break;
    case 'OW':
      setRef([...ref, getOwnerRef(people[personIndex], type, personIndex)] as RefOwner[]);
      break;
    case 'LR':
      if (type === 'person')
        setRef([
          ...ref,
          getLegalRepresentativeRef(people[personIndex] as Person, personIndex),
        ] as RefLegalRepresentative[]);
      break;
    case 'LA':
      if (type === 'person')
        setRef([
          ...ref,
          getLoanApplicantRef(people[personIndex] as Person, personIndex),
        ] as RefLoanApplicant[]);
      break;
    default:
      break;
  }
};

export const deleteRoleRefs = (
  role: GeneralRole,
  personIndex: number,
  arrRoleRefs: RefArrays,
  setRef: SetNewFunction,
) => {
  if (personIndex === -1) return;
  const arrRefs = [...arrRoleRefs];
  arrRefs.splice(personIndex, 1);
  switch (role) {
    case 'BO':
      setRef(arrRefs as RefBeneficialOwner[]);
      break;
    case 'OW':
      setRef(arrRefs as RefOwner[]);
      break;
    case 'LR':
      setRef(arrRefs as RefLegalRepresentative[]);
      break;
    case 'LA':
      setRef(arrRefs as RefLoanApplicant[]);
      break;
    default:
      break;
  }
};

export const regexForValidNumber = /^\d{1,4}(?:\.\d{1,2})?$/;

export const getExceptionsLegalForms = (legalForm: string) =>
  legalForm === GbRLegalForm || legalForm === PartGLegalForm || legalForm === PartGmbBLegalForm;

export const noSideDealsDisplayLabel = (legalForm?: string) =>
  legalForm === EKLegalFormLabel ||
  legalForm === FreelanceLegalFormLabel ||
  legalForm === GbRLegalForm ||
  legalForm === PartGmbBLegalForm ||
  legalForm === PartGLegalForm;

export const noSideDealsDisplayKey = (legalForm: string | undefined | null) =>
  legalForm === EKLegalForm ||
  legalForm === FreelanceLegalForm ||
  legalForm === GbRLegalFormKey ||
  legalForm === PartGmbBLegalFormKey ||
  legalForm === PartGLegalFormKey;

export function getYesOrNoLabelRestrict(status?: YesOrNo) {
  switch (status) {
    case 'yes':
      return 'Ja';
    case 'no':
    default:
      return 'Nein';
  }
}

export const getNeedsAllocation = (legalForm: string) =>
  legalForm === GbRLegalFormKey ||
  legalForm === PartGLegalFormKey ||
  legalForm === PartGmbBLegalFormKey;

export const getHaveAllAllocation = (legalForm: string) =>
  legalForm === EKLegalForm || legalForm === FreelanceLegalForm;

export const getTotalShareValue = (people: Person[] = [], companies: Company[] = []) => {
  const totalPeopleShare = people.reduce(
    (prev, cur) =>
      prev +
      cur.shares.reduce((prevShare, curShare) => Number(curShare.allocation ?? 0) + prevShare, 0),
    0,
  );
  const totalCompanyShare = companies.reduce(
    (prev, cur) =>
      prev +
      cur.shares.reduce((prevShare, curShare) => Number(curShare.allocation ?? 0) + prevShare, 0),
    0,
  );
  return totalPeopleShare + totalCompanyShare;
};

const MIN_ALLOCATION_LVL_1 = 25;
const MIN_ALLOCATION_LVL_2 = 50;

export const sharesValidator = (
  allocation: string,
  sideDeal: YesOrNo,
  level: Level,
  parentCompanyName: string,
  isBeneficialOwner: YesOrNo = '',
  checkSideDeal = true,
) => {
  const currentAllocation = getLocaleAllocation(allocation, 'de');
  if (currentAllocation < 0) {
    return undefined;
  }
  if (level === 1 && currentAllocation <= MIN_ALLOCATION_LVL_1) {
    if (sideDeal !== 'yes' && isBeneficialOwner === 'yes' && checkSideDeal) {
      return `Anteile oder Stimmäquivalent an ${parentCompanyName} sind nicht über ${MIN_ALLOCATION_LVL_1}%.`;
    }
  }
  if (level === 1 && currentAllocation <= MIN_ALLOCATION_LVL_2) {
    if (sideDeal === '' && isBeneficialOwner === 'yes' && checkSideDeal) {
      return 'Bitte machen Sie noch Angaben zu den vergleichbaren Kontrollrechten.';
    }
  }
  if ((level === 2 || level === 3) && currentAllocation <= MIN_ALLOCATION_LVL_2) {
    if (sideDeal !== 'yes' && checkSideDeal) {
      return `Anteile oder Stimmäquivalent an ${parentCompanyName} sind nicht über ${MIN_ALLOCATION_LVL_2}%.`;
    }
  }
  return undefined;
};

export const CLOCK_STATUS = {
  STARTED: 'Started',
  STOPPED: 'Stopped',
};

export const getSideDeal = (allocation: number, sideDeal: YesOrNo | undefined, level?: Level) => {
  if (level === undefined || level === 1)
    return allocation <= 50 ? returnBooleanForBackend(sideDeal) : false;
  return returnBooleanForBackend(sideDeal);
};

export const handleAllocationChangeFunction = async (
  customerProfileId: CustomerProfileIdType,
  allocation: string,
  sideDeal: YesOrNo | undefined,
  showCheck: string,
  shareID: number | undefined,
  companyOrPerson: 'person' | 'company',
  entityID: number,
  parentID: number | null,
  setLoadingAllocation: React.Dispatch<React.SetStateAction<boolean>>,
  setShowCheck: React.Dispatch<React.SetStateAction<string>>,
  setAllocationStatus: React.Dispatch<React.SetStateAction<string | null>>,
  level?: Level,
) => {
  try {
    setLoadingAllocation(true);
    if (showCheck === CLOCK_STATUS.STARTED) setShowCheck(CLOCK_STATUS.STOPPED);
    const currentAllocation = getLocaleAllocation(allocation, 'de');
    await request.put(ApiCaptiq.SAVE_SHARES(shareID, customerProfileId), {
      id: shareID,
      parent_company: parentID,
      owner_company: companyOrPerson === 'company' ? entityID : null,
      owner_person: companyOrPerson === 'person' ? entityID : null,
      allocation_of_shares: currentAllocation,
      side_deal: getSideDeal(currentAllocation, sideDeal, level),
    });
    setShowCheck(CLOCK_STATUS.STARTED);
    setAllocationStatus('success');
  } catch (error: any) {
    setShowCheck(CLOCK_STATUS.STARTED);
    setAllocationStatus('error');
  } finally {
    setLoadingAllocation(false);
  }
};

export const getCurrentIconFunction = (
  loadingAllocation: boolean,
  allocationStatus: string | null,
) => {
  if (loadingAllocation) {
    return (
      <Box ml={1} display="flex">
        <CircularProgress color="primary" size={18} />
      </Box>
    );
  }
  if (allocationStatus) {
    if (allocationStatus === 'success') {
      return (
        <Box ml={1} display="flex">
          <Lottie
            width={30}
            height={30}
            options={{
              animationData: CheckIcon,
              loop: false,
            }}
            speed={2}
          />
        </Box>
      );
    }
    if (allocationStatus === 'error') {
      return (
        <Box ml={1} display="flex">
          <Lottie
            width={30}
            height={30}
            options={{
              animationData: ErrorIcon,
              loop: false,
            }}
            speed={2}
          />
        </Box>
      );
    }
  }
  return null;
};

export const getTotalShareValueWithParent = (
  parentID: number,
  people: Person[] = [],
  companies: Company[] = [],
) => {
  const totalPeopleShare = people.reduce(
    (prev, cur) =>
      prev +
      cur.shares.reduce(
        (prevShare, curShare) =>
          curShare.parent !== parentID
            ? prevShare + 0
            : Number(curShare.allocation?.replace(/,/g, '.') ?? 0) + prevShare,
        0,
      ),
    0,
  );
  const totalCompanyShare = companies.reduce(
    (prev, cur) =>
      prev +
      cur.shares.reduce(
        (prevShare, curShare) =>
          curShare.parent !== parentID
            ? prevShare + 0
            : Number(curShare.allocation?.replace(/,/g, '.') ?? 0) + prevShare,
        0,
      ),
    0,
  );
  return totalPeopleShare + totalCompanyShare;
};

export const hideBlockOnCondition = (
  dependingField: string,
  visibilityValue: string,
  mainCompanyLegalForm: string,
) => {
  if (dependingField === LEGAL_FORM_SLUG && mainCompanyLegalForm) {
    if (visibilityValue === mainCompanyLegalForm) return false;
    return true;
  }
  return false;
};

export const canRenderCreateMissingInfoButton = (status: string) => {
  const canRender =
    status === ApiCaptiq.STATUS_IDENTIFICATION_RECEIVED ||
    status === ApiCaptiq.STATUS_IDENTIFICATION_PENDING ||
    status === ApiCaptiq.STATUS_IDENTIFICATION_SUCCESSFULL ||
    status === ApiCaptiq.STATUS_IDENTIFICATION_UNSUCCESSFULL ||
    status === ApiCaptiq.STATUS_BANK_CHECK_1 ||
    status === ApiCaptiq.STATUS_BANK_REQUEST_AVAILABLE ||
    status === ApiCaptiq.STATUS_BANK_REQUEST_PENDING ||
    status === ApiCaptiq.STATUS_BANK_REQUEST_RESPONSE ||
    status === ApiCaptiq.STATUS_BANK_CHECK_2;
  return canRender && process.env.REACT_APP_DEBUG_SOLARISBANK_BUTTONS === 'on';
};

export const canRenderRequestAvailableButton = (status: string) => {
  const canRender =
    status === ApiCaptiq.STATUS_IDENTIFICATION_PENDING ||
    status === ApiCaptiq.STATUS_BANK_CHECK_1 ||
    status === ApiCaptiq.STATUS_BANK_CHECK_2;
  return canRender && process.env.REACT_APP_DEBUG_SOLARISBANK_BUTTONS === 'on';
};

export const canRenderButtons = (status: string) => {
  const canRender =
    status === ApiCaptiq.STATUS_BANK_CHECK_1 ||
    status === ApiCaptiq.STATUS_BANK_CHECK_2 ||
    status === ApiCaptiq.STATUS_API_TRANSFER ||
    status === ApiCaptiq.STATUS_IDENTIFICATION_PENDING ||
    status === ApiCaptiq.STATUS_IDENTIFICATION_RECEIVED ||
    status === ApiCaptiq.STATUS_IDENTIFICATION_SUCCESSFULL;
  return canRender;
};

export const canRenderEmailCheckButton = (status: string) => {
  const canRender =
    status === ApiCaptiq.STATUS_BANK_CHECK_1 ||
    status === ApiCaptiq.STATUS_BANK_CHECK_2 ||
    status === ApiCaptiq.STATUS_BANK_REQUEST_PENDING ||
    status === ApiCaptiq.STATUS_BANK_REQUEST_RESPONSE ||
    status === ApiCaptiq.STATUS_IDENTIFICATION_PENDING ||
    status === ApiCaptiq.STATUS_IDENTIFICATION_RECEIVED ||
    status === ApiCaptiq.STATUS_IDENTIFICATION_SUCCESSFULL ||
    status === ApiCaptiq.STATUS_PAYMENT_PENDING;
  return canRender;
};

export const getRefinancingChannelName = (name: string) => {
  switch (name) {
    case 'Solarisbank':
      return 'CAPTIQ Compartment 2019-001';
    case 'Anadibank':
      return 'Austrian Anadi Bank';
    default:
      return name;
  }
};

export const saveLoanUsage = (loanUsages: any, loan_usage: string, response: any) => {
  const loanUsageName = loanUsages.filter((usage: any) => usage.id === loan_usage)[0]?.name;
  localStorage.setItem(
    'navigationState',
    JSON.stringify({
      loanId: response.data.id,
      from: 'partner-page',
      loanUsage: loan_usage,
      loanUsageName,
    }),
  );
};

export const getSearchString = (
  isPool: any,
  tipster_partner: any,
  token: any,
  tipster_employee: any,
) => {
  const realTipsterEmployee = token ? tipster_employee : undefined;
  const realToken = token ?? tipster_employee;
  let searchString = '';
  if (isPool) {
    searchString = tipster_partner
      ? `${searchString}tipster_pool=${tipster_partner}`
      : searchString;
    searchString = realToken ? `${searchString}&token=${realToken}` : searchString;
  } else {
    searchString = tipster_partner
      ? `${searchString}tipster_partner=${tipster_partner}`
      : searchString;
    searchString = realTipsterEmployee
      ? `${searchString}&tipster_employee=${realTipsterEmployee}`
      : searchString;
    searchString = realToken ? `${searchString}&token=${realToken}` : searchString;
  }
  return searchString;
};
