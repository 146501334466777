import { useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from '@mui/material';

import { request } from '../../service/ApiCaptiq';
import * as ApiCaptiq from '../../service/ApiCaptiq';
import PredefinedComments from './PredefinedComments';
import useStyles from './styles';

const NO_PREDEFINED_COMMENTS_MSG = { title: '<Keine Kommentare gefunden>', type: '' };

function SendComment(props) {
  const [predefinedComments, setPredefinedComments] = useState([NO_PREDEFINED_COMMENTS_MSG]);
  const [openPredefinedComments, setOpenPredefinedComments] = useState(false);
  const [elementPredefined, setElementPredefined] = useState();
  const [newComment, setNewComment] = useState({ comment: '', type: 'IC' });
  const classes = useStyles();

  const getComments = async (event) => {
    setOpenPredefinedComments(true);
    setElementPredefined(event.currentTarget);

    try {
      const response = await request.get(ApiCaptiq.PREDEFINED_COMMENTS_URL);
      if (response.data.length > 0) {
        setPredefinedComments(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const saveComment = async () => {
    if (!newComment || newComment.comment.length <= 0) return;
    try {
      const response = await request.post(`${ApiCaptiq.LOAN_APPLICATION_COMMENT_URL}`, {
        loan_application: props.loanApplication.id,
        path: props.section,
        comment: newComment.comment,
        type: newComment.type,
      });
      props.onCommentSaved(response.data);
      setNewComment({ comment: '', type: 'IC' });
    } catch (e) {
      console.error(e);
    }
  };

  const chooseComment = (comment) => {
    setNewComment(comment);
    setOpenPredefinedComments(false);
  };

  const updateCommentComment = (ev) => {
    const comment = ev.target.value;
    setNewComment((prevValue) => ({ ...prevValue, comment }));
  };

  return (
    <div>
      <textarea
        className={classes.textArea}
        placeholder="Geben Sie Ihren Kommentar ein"
        onChange={updateCommentComment}
        value={newComment.comment}
      />
      <Button size="small" color="primary" variant="contained" onClick={saveComment}>
        Kommentar hinterlassen
      </Button>
      <Button style={{ float: 'right' }} onClick={getComments}>
        Wähle einen Kommentar
      </Button>
      <PredefinedComments
        open={openPredefinedComments}
        element={elementPredefined}
        onClose={() => setOpenPredefinedComments(false)}
        onChoose={(comment) => chooseComment(comment)}
        comments={predefinedComments}
      />
    </div>
  );
}

SendComment.defaultPropTypes = {
  onCommentSaved: () => {},
};

SendComment.propTypes = {
  loanApplication: PropTypes.object,
  section: PropTypes.string,
  onCommentSaved: PropTypes.func,
};

export default SendComment;
