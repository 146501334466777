import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
  blockOutlined: {
    padding: 15,
    borderRadius: 5,
    backgroundColor: '#f9f9f9',
  },
  questionTitle: {
    fontWeight: 500,
    color: '#000',
  },
  customTitle: {
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
  questionSubtitle: {
    fontSize: 17,
    color: '#000',
  },
  underlineSubtitle: {
    textDecorationLine: 'underline',
  },
  divider: {
    borderTop: '2px dashed',
    borderTopColor: theme.palette.primary.main,
    marginTop: 15,
  },
  inlineFields: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  deleteButton: {
    marginTop: 10,
    backgroundColor: '#ffe1e6',
    color: '#940001',
    padding: '0 8px',
    '&:hover': {
      backgroundColor: '#e2bbbb',
      color: '#550000',
    },
  },
  containerTitle: {
    display: 'flex',
    marginBottom: '10px',
    flexFlow: 'column',
  },
  fieldContainer: {
    display: 'flex',
  },
  fieldContainerRichText: {
    flexDirection: 'column',
  },
}));
