import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { FormikErrors, useFormikContext } from 'formik';
import _get from 'lodash.get';

import { FormHelperText, Grid, Typography } from '@mui/material';

import { YesOrNo } from 'utils/commonTypes/types';
import {
  getLocaleAllocation,
  getYesOrNOLabel,
  noSideDealsDisplayLabel,
  getYesOrNoLabelRestrict,
} from 'utils/utils';
import { useCustomerData } from 'context/customer-context';
import { OCCUPATIONAL_GROUPS, getOccupationalGroupLabel } from 'constants/occupationalGroups';
import { SALUTATIONS, getSalutationLabel } from 'constants/salutations';
import { TITLES, getTitleLabel } from 'constants/titles';
import { LANGUAGE } from 'constants/language';
import { FAMILY_STATUSES, getFamilyStatusLabel } from 'constants/familyStatuses';
import { InitialValues, Person } from 'context/customer-context/types';
import CustomField from 'components/StaticBlocks/CustomField';
import { EKLegalFormLabel, FreelanceLegalFormLabel, GbRLegalForm } from 'constants/legalForms';
import WarningMessage from 'components/WarningMessage';
import { useLazyEffect } from 'hooks/useLazyEffect';
import { useDataContext } from 'context/data-context';
import { STARTUP_TEMPLATE_TAG, TAKEOVER_PERSON_TEMPLATE_TAG } from 'constants/templates';
import { useDynamicTexts } from 'context/dynamic-texts-context';
import { showAgeWarningMessage } from './validations/showAgeWarningMessage';

interface Props {
  personIndex: number;
  formPerson: Person;
  isEditing?: boolean;
  isStartUpTemplate?: boolean;
  showDateOfBirthWarning?: boolean;
}

const PersonalData = memo(
  ({
    personIndex,
    isEditing = false,
    isStartUpTemplate = false,
    showDateOfBirthWarning = true,
  }: Props) => {
    const { contractSignatoryOrLoanApplicant } = useDynamicTexts();
    const { currentLegalForm, mainCompany } = useCustomerData();
    const { values, errors, setFieldValue, validateField } = useFormikContext<InitialValues>();
    const { loanApplicationUsage, loanApplicationDuration } = useDataContext();
    const yesNoArray: YesOrNo[] = ['yes', 'no'];

    const amountLegitimacy = useMemo(
      () =>
        values.people.reduce((numberLegitimacy: number, p: Person) => {
          if (p.isLoanApplicant === 'yes' && p.isLegitimacy === 'yes')
            // This is necessary because it's a counter
            // eslint-disable-next-line no-param-reassign
            numberLegitimacy += 1;
          return numberLegitimacy;
        }, 0),
      [values.people],
    );

    useLazyEffect(() => {
      if (!isEditing && values.people[personIndex])
        validateField(`people[${personIndex}].isLegitimacy`);
    }, [personIndex, isEditing, validateField, values.people]);

    const validateLegitimacy = useCallback(() => {
      if (!isStartUpTemplate && currentLegalForm?.label !== GbRLegalForm && amountLegitimacy === 0)
        return `Mindestens ein ${contractSignatoryOrLoanApplicant} muss für die Vertragsunterzeichnung und für die Identifizierung bestimmt werden`;
      return undefined;
    }, [
      isStartUpTemplate,
      currentLegalForm?.label,
      amountLegitimacy,
      contractSignatoryOrLoanApplicant,
    ]);

    const handleChangeSoleRepresentation = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setFieldValue(`people[${personIndex}].isSoleRepresentation`, value);
        setTimeout(() => {
          validateField(`people[${personIndex}].isSoleRepresentation`);
        }, 0);
      },
      [setFieldValue, personIndex, validateField],
    );

    const showLegitimacyError = useCallback(() => {
      const errorPerson =
        errors.people &&
        errors.people[personIndex] &&
        (errors.people[personIndex] as FormikErrors<Person>);
      const hasError = errorPerson && errorPerson.isLegitimacy;
      if (currentLegalForm?.label === GbRLegalForm)
        return (
          hasError && (
            <FormHelperText error>
              Jeder Inhaber einer GbR muss sich für ein Darlehen legitimieren
            </FormHelperText>
          )
        );
      if (currentLegalForm?.label !== GbRLegalForm && amountLegitimacy > 1) {
        return (
          hasError && (
            <FormHelperText error>
              Es kann für die Vertragsunterzeichnung und Identifizierung nur ein{' '}
              {contractSignatoryOrLoanApplicant} verantwortlich sein
            </FormHelperText>
          )
        );
      }
      return (
        hasError && (
          <FormHelperText error>
            Mindestens ein {contractSignatoryOrLoanApplicant} muss für die Vertragsunterzeichnung
            und für die Identifizierung bestimmt werden
          </FormHelperText>
        )
      );
    }, [
      errors.people,
      personIndex,
      currentLegalForm?.label,
      amountLegitimacy,
      contractSignatoryOrLoanApplicant,
    ]);

    useEffect(() => {
      if (
        !(
          loanApplicationUsage === STARTUP_TEMPLATE_TAG ||
          loanApplicationUsage === TAKEOVER_PERSON_TEMPLATE_TAG
        )
      ) {
        if (
          currentLegalForm?.label === FreelanceLegalFormLabel ||
          currentLegalForm?.label === EKLegalFormLabel
        ) {
          setFieldValue(`people[${personIndex}].isSoleRepresentation`, 'yes');
        } else if (currentLegalForm?.label === GbRLegalForm) {
          setFieldValue(`people[${personIndex}].isSoleRepresentation`, 'no');
        }
      }
    }, [currentLegalForm?.label, loanApplicationUsage, personIndex, setFieldValue]);

    return (
      <Grid container item spacing={2}>
        {values.people[personIndex].isLegitimacy === 'yes' && (
          <CustomField
            name={`people[${personIndex}].isLegitimacy`}
            title="Übernimmt Vertragszeichnung und Legitimierung"
            tooltip="Pro Antrag kann es nur eine Person geben, welche die Legitimierung nach Einreichung
          des Darlehensantrags durchführt sowie den Vertrag im Namen aller unterzeichnet."
            type="radiogroup"
            disabled={!isEditing}
            validate={validateLegitimacy}
            radioShowNo={false}
            customError={showLegitimacyError()}
          />
        )}
        {loanApplicationUsage === STARTUP_TEMPLATE_TAG ||
        loanApplicationUsage === TAKEOVER_PERSON_TEMPLATE_TAG ? null : (
          <>
            {_get(values, `people[${personIndex}].isSoleRepresentation`) === 'no' &&
              currentLegalForm?.label !== GbRLegalForm && (
                <WarningMessage
                  message="Aufgrund Ihrer Angabe zur Vertretungsbefugnis kann CAPTIQ Ihnen leider keine Finanzierung anbieten, da zurzeit keine Unternehmen mit Gesamtvertretungsbefugnis finanziert werden können. Das Anlegen eines Kundenprofils ist zum Verwaltungszweck jedoch weiterhin möglich."
                  severity="error"
                />
              )}
            <CustomField
              required
              name={`people[${personIndex}].isSoleRepresentation`}
              title="Ist die Person einzelvertretungsberechtigt?"
              type="radiogroup"
              disabled={!isEditing}
              onChange={handleChangeSoleRepresentation}
              radioShowYes={currentLegalForm?.label !== GbRLegalForm}
              radioShowNo={
                !(
                  currentLegalForm?.label === FreelanceLegalFormLabel ||
                  currentLegalForm?.label === EKLegalFormLabel
                )
              }
            />
          </>
        )}
        <CustomField
          data={SALUTATIONS}
          getLabel={getSalutationLabel}
          label="Bitte wählen"
          name={`people[${personIndex}].salutation`}
          required
          title="Anrede"
          type="select"
          disabled={!isEditing}
        />
        <CustomField
          data={TITLES}
          getLabel={getTitleLabel}
          label="Bitte wählen"
          name={`people[${personIndex}].title`}
          title="Titel"
          type="select"
          disabled={!isEditing}
        />
        <CustomField
          name={`people[${personIndex}].firstNames`}
          required
          title="Vorname"
          type="text"
          disabled={!isEditing}
        />
        <CustomField
          name={`people[${personIndex}].lastName`}
          required
          title="Nachname"
          type="text"
          disabled={!isEditing}
        />
        <CustomField
          name={`people[${personIndex}].birthName`}
          title="Geburtsname"
          type="text"
          disabled={!isEditing}
        />
        {!!loanApplicationDuration &&
          personIndex !== undefined &&
          personIndex !== null &&
          _get(values, `people[${personIndex}].dateOfBirth`) &&
          showDateOfBirthWarning &&
          showAgeWarningMessage({
            birthDate: _get(values, `people[${personIndex}].dateOfBirth`),
            firstName: _get(values, `people[${personIndex}].firstNames`),
            lastName: _get(values, `people[${personIndex}].lastName`),
            loanApplicationUsage,
            mainCompany,
            loanApplicationDuration,
            isStartUpTemplate,
          })}
        <CustomField
          name={`people[${personIndex}].dateOfBirth`}
          required
          title="Geburtsdatum"
          type="date"
          maxDate={new Date()}
          disabled={!isEditing}
        />
        <CustomField
          title="Geburtsland"
          name={`people[${personIndex}].countryOfBirth`}
          textFieldProps={{ label: 'Bitte wählen' }}
          type="place"
          placetype="country"
          disabled={!isEditing}
          required
        />
        <CustomField
          required
          title="Geburtsort"
          name={`people[${personIndex}].cityOfBirth`}
          type="text"
          disabled={!isEditing}
        />
        <CustomField
          title="Staatsangehörigkeit"
          name={`people[${personIndex}].nationality`}
          textFieldProps={{ label: 'Bitte wählen' }}
          type="place"
          placetype="nationality"
          disabled={!isEditing}
          required
        />
        <CustomField
          data={OCCUPATIONAL_GROUPS}
          getLabel={getOccupationalGroupLabel}
          label="Bitte wählen"
          name={`people[${personIndex}].occupationalGroup`}
          title="Berufsgruppe"
          type="select"
          disabled={!isEditing}
          required
        />
        <CustomField
          data={FAMILY_STATUSES}
          getLabel={getFamilyStatusLabel}
          label="Bitte wählen"
          name={`people[${personIndex}].familySituation`}
          title="Familienstatus"
          type="select"
          disabled={!isEditing}
          required
        />
        {_get(values, `people[${personIndex}].taxableInUSA`) === 'yes' && (
          <WarningMessage
            message="Aufgrund Ihrer Angaben zur Steuerpflicht in den USA kann CAPTIQ Ihnen leider keine Finanzierung anbieten. Das Anlegen eines Kundenprofils ist zum Verwaltungszweck jedoch weiterhin möglich."
            severity="error"
          />
        )}
        <CustomField
          data={yesNoArray}
          getLabel={getYesOrNOLabel}
          label="Bitte wählen"
          name={`people[${personIndex}].taxableInUSA`}
          title="Steuerpflichtig in den USA? (FATCA)"
          type="select"
          disabled={!isEditing}
          required
        />
        {!isStartUpTemplate &&
          values.people[personIndex] &&
          values.people[personIndex].shares &&
          values.people[personIndex].shares[0] && (
            <>
              <CustomField title="Anteile am Unternehmen">
                <Typography>
                  {`${getLocaleAllocation(
                    values.people[personIndex].shares[0].allocation,
                    LANGUAGE,
                  )}%`}
                </Typography>
              </CustomField>
              <CustomField title="Inhaber(in)">
                <Typography>
                  {getYesOrNoLabelRestrict(values.people[personIndex].isOwner)}
                </Typography>
              </CustomField>
              <CustomField title="Wirtschaftlich Berechtigte">
                <Typography>
                  {getYesOrNoLabelRestrict(values.people[personIndex].isBeneficialOwnerLevelOne)}
                </Typography>
              </CustomField>
              {!noSideDealsDisplayLabel(currentLegalForm?.label) && (
                <CustomField title="Kann die Person auf vergleichbare Weise Kontrolle ausüben?">
                  <Typography>
                    {getYesOrNoLabelRestrict(values.people[personIndex].shares[0].sideDeal)}
                  </Typography>
                </CustomField>
              )}
              <CustomField title="Gesetzliche(r) Vertreter(in)">
                <Typography>
                  {getYesOrNoLabelRestrict(values.people[personIndex].isLegalRepresentative)}
                </Typography>
              </CustomField>
            </>
          )}
      </Grid>
    );
  },
);

PersonalData.displayName = 'PersonalData';

export default PersonalData;
