import classNames from 'classnames';
import PropTypes from 'prop-types';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Popover from '@mui/material/Popover';

import useStyles from './styles';

function PredefinedComments({ open, element, onChoose, onClose, comments }) {
  const classes = useStyles();
  return (
    <Popover
      id="pop"
      open={open}
      anchorEl={element}
      onClose={() => onClose(false)}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <List className={classes.predefinedComments}>
        {comments.map((comment, index) => (
          <ListItem
            className={classNames(
              classes.commentBlockSmall,
              comment.type === 'IC' ? classes.commentBlockPrivate : '',
            )}
            button
            key={index}
            onClick={() => onChoose(comment)}
          >
            <span>{comment.title}</span>
          </ListItem>
        ))}
      </List>
    </Popover>
  );
}

PredefinedComments.defaultProps = {
  open: false,
  comments: [],
};

PredefinedComments.propTypes = {
  open: PropTypes.bool,
  element: PropTypes.object,
  onClose: PropTypes.func,
  onChoose: PropTypes.func,
  comments: PropTypes.array,
};

export default PredefinedComments;
