import { AlertColor } from '@mui/material';

import { MIN_AGE } from 'constants/validations';
import { HOCHLADEN_BUSINESSPLAN, QUESTION_HOCHLADEN_BUSINESSPLAN } from './constants';

export const getMapFieldMessage = (
  dataSlug: string,
  dataFieldValidator: any,
  blockSlug: string | number,
  questionDataSlug: any,
  blocks: any,
) => {
  let warningMessage = '';
  let maxDate;
  let severity: AlertColor = 'error';
  if (dataFieldValidator) {
    const valueFromBlock = blocks[blockSlug]?.questions[`q_${dataSlug}`]?.fields[dataSlug]?.value;

    switch (dataFieldValidator) {
      case 'FATCA':
        if (valueFromBlock === 'True') {
          warningMessage =
            'Aufgrund Ihrer Angaben zur Steuerpflicht in den USA kann CAPTIQ Ihnen leider keine Finanzierung anbieten. Das Anlegen eines Kundenprofils ist zum Verwaltungszweck jedoch weiterhin möglich.';
        }
        break;
      case 'FOUNDATION_DATE_STARTUP':
        if (valueFromBlock && new Date(valueFromBlock) <= new Date()) {
          warningMessage =
            'Bei einer Gründungsfinanzierung darf das Unternehmen noch nicht gegründet sein. Sollte das Unternehmen bereits bestehen, wählen Sie bitte einen anderen Verwendungszweck.';
        }
        break;
      case 'FOUNDATION_DATE_ONGOING':
        if (valueFromBlock && new Date(valueFromBlock) >= new Date()) {
          warningMessage =
            'Bei einer Anschlussfinanzierung (Unternehmen, JA vorhanden) muss das Unternehmen bereits gegründet sein. Sollte das Unternehmen noch nicht bestehen, wählen Sie bitte den Verwendungszweck "Gründung (Unternehmen noch nicht gegründet)".';
        }
        break;
      case 'FOUNDATION_DATE_TAKEOVER_PERSON':
        if (valueFromBlock && new Date(valueFromBlock) >= new Date()) {
          warningMessage =
            'Bei einer Übernahme (Person übernimmt Anteile von Unternehmen) muss das zu übernehmende Unternehmen bereits gegründet sein. Sollte das Unternehmen noch nicht bestehen, wählen Sie bitte den Verwendungszweck "Gründung (Unternehmen noch nicht gegründet)".';
        }
        break;
      case 'FOUNDATION_DATE_PROCESS': {
        const valueDate = new Date(valueFromBlock);
        const newDate = new Date();
        if (valueFromBlock && valueDate >= newDate) {
          warningMessage =
            'Bei einer Anschlussfinanzierung (Unternehmen, JA vorhanden) muss das Unternehmen bereits gegründet sein. Sollte das Unternehmen noch nicht bestehen, wählen Sie bitte den Verwendungszweck "Gründung (Unternehmen noch nicht gegründet)".';
        }
        newDate.setFullYear(new Date().getFullYear() - 2);
        if (valueFromBlock && valueDate <= newDate) {
          warningMessage =
            'Ihre Gründung liegt bereits mehr als 2 Jahre zurück. Bitte wählen Sie einen anderen Verwendungszweck und reichen Sie Ihre aktuellsten Bilanz-Dokumente ein.';
        }
        break;
      }

      case 'BIRTHDATE':
        maxDate = new Date();
        if (
          valueFromBlock &&
          maxDate.getFullYear() - new Date(valueFromBlock).getFullYear() < MIN_AGE
        ) {
          warningMessage = `Wir bitten um Verständnis, dass wir Darlehen an Gründer erst ab ${MIN_AGE} Jahren vergeben können.`;
        }
        break;
      case 'CHAMBER':
        maxDate = new Date();
        break;
      default:
        break;
    }
  }

  if (questionDataSlug === QUESTION_HOCHLADEN_BUSINESSPLAN && dataSlug === HOCHLADEN_BUSINESSPLAN) {
    severity = 'info';
    warningMessage = `Bitte beachten Sie, dass der eigene hochgeladene Business Plan folgende Themen abdeckt: Konzept, Wachstumsstrategie, Erfahrung Gründer, Preisgestaltung, Marktanalyse, Marketing, Personal.`;
  }
  return { warningMessage, maxDate, severity };
};
