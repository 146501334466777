import * as Yup from 'yup';

import { LATabCompletionStateType } from 'components/StaticBlocks/LoanApplicants/types';
import {
  addressValidationSchema,
  contactValidationSchema,
  personalDataValidationSchema,
} from 'components/StaticBlocks/LoanApplicants/validationSchema';
import {
  getValidationErrors,
  Response,
} from 'utils/validateStaticBlocksValues/getValidationErrors';
import { Errors } from 'utils/validateStaticBlocksValues';
import { InitialValues } from './constants';
import { RefContext, RefLoanApplicant } from './types';

export const validateLAStaticValues = async (
  initialValues: InitialValues,
  refsLoanApplicants: RefLoanApplicant[],
) => {
  const tabsStatuses: LATabCompletionStateType[] = [];

  let dateOfBirthOneIsOK = false;
  const result = refsLoanApplicants.map(async (ref: RefContext, index: number) => {
    let personalData = true;
    let address = true;
    let contact = true;
    let personalDataError: Response[] = [];
    let addressError: Response[] = [];
    let contactError: Response[] = [];
    const optionsForm = {
      abortEarly: false,
      context: {
        company:
          (initialValues as InitialValues).mainCompany || (initialValues as InitialValues).company,
        loanApplicationUsage: (initialValues as InitialValues).loanApplicationUsage,
        loanApplicationDuration: (initialValues as InitialValues).loanApplicationDuration,
      },
    };

    // This try/catch independance are needed in order to execute all validations
    try {
      await personalDataValidationSchema.validate(
        (initialValues as InitialValues).people[ref.index],
        optionsForm,
      );
      dateOfBirthOneIsOK = true;
    } catch (err) {
      personalDataError = getValidationErrors(err as Yup.ValidationError);
      personalData = false;
    }
    try {
      await addressValidationSchema.validate(
        (initialValues as InitialValues).people[ref.index],
        optionsForm,
      );
    } catch (err) {
      addressError = getValidationErrors(err as Yup.ValidationError);
      address = false;
    }
    try {
      await contactValidationSchema.validate(
        (initialValues as InitialValues).people[ref.index],
        optionsForm,
      );
    } catch (err) {
      contactError = getValidationErrors(err as Yup.ValidationError);
      contact = false;
    }
    tabsStatuses[index] = {
      personalData: { complited: personalData, errors: personalDataError },
      address: { complited: address, errors: addressError },
      contact: { complited: contact, errors: contactError },
    };
  });
  await Promise.all(result);

  // Apply exceptions
  const globalTabsStatuses = tabsStatuses.map((v) => {
    if (dateOfBirthOneIsOK) {
      const index = v.personalData.errors.findIndex((e) => e.message === 'dateOfBirth error');
      if (index !== -1) v.personalData.errors.splice(index, 1);
      if (v.personalData.errors.length === 0) v.personalData.complited = true;
    }
    return v;
  });

  return globalTabsStatuses;
};

export const validateBirthDate = (
  tempTabsStatuses: boolean[],
  tempErrorsYUP: Errors[],
  refPeople: any[],
) => {
  let errorsYUP = [...tempErrorsYUP];
  const tabsStatuses = [...tempTabsStatuses];

  if (tabsStatuses.some((status) => !status)) {
    let birthdayErrorInAll = false;
    const someBirthdayError = errorsYUP.some((item) =>
      item.errors.some((error) => error.message === 'dateOfBirth error'),
    );

    if (refPeople.length === errorsYUP.length) {
      birthdayErrorInAll = errorsYUP.every((item) =>
        item.errors.some((error) => error.message === 'dateOfBirth error'),
      );
    }

    if (!birthdayErrorInAll && someBirthdayError) {
      errorsYUP = errorsYUP.reduce((acc, item) => {
        const newErrors = item.errors.filter((error) => error.message !== 'dateOfBirth error');
        if (newErrors.length) {
          const newItem = { ...item, errors: newErrors };
          return [...acc, newItem];
        }
        tabsStatuses[item.position] = true;
        return acc;
      }, [] as Errors[]);
    }
  }

  return [tabsStatuses, errorsYUP];
};
