import React, { memo, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { isValidIBAN, electronicFormatIBAN } from 'ibantools';

import { Button, Grid, Typography } from '@mui/material';
import { Add, Delete, CheckCircleRounded, CancelOutlined } from '@mui/icons-material';

import { Question, QuestionField, QuestionInstance } from 'utils/commonTypes/types';
import { isAdmin } from 'utils/isAdmin';
import { replaceWithNode } from 'utils/replaceWithNode';
import { underlineWord } from 'utils/underlineWord';
import { shouldHideQuestion } from 'utils/dataHandlers';
import { GET_BIC_BANK_NAME, request } from 'service/ApiCaptiq';
import * as ApiCaptiq from 'service/ApiCaptiq';
import { useDataContext } from 'context/data-context';
import { useCustomerData } from 'context/customer-context';
import { useIbanStatus } from 'context/iban-context';
import { useAuth } from 'context/auth-context';
import { QUESTION_SONSTINGE_DOKUMENTS } from './constants';
import LoadingIndicator from '../LoadingIndicator';
import SimpleLink from '../SimpleLink';
import HelperTextFieldDocument from '../HelperTextFieldDocument';
import { ICaptiqQuestionsProps } from './types';
import useStyles from './styles';
import Field from './components/Field';

const CaptiqQuestions = memo((props: ICaptiqQuestionsProps) => {
  const {
    scaffold,
    blockSlug,
    renderExtraValidationInfo,
    onChange,
    deleteField,
    loanId,
    stepOrder,
    disabled,
    blockData,
    questions,
    title,
    blockHelper,
    customColors,
    personID,
    reviewCancelEdition,
    showCopyButton,
    upperFormState,
    blockDeepSlug,
    step_id,
    block_id,
  } = props;
  const styles = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [form, setForm] = useState<any>({});
  const [questionData, setQuestionData] = useState<Question>({ ...scaffold });
  const [isDeleting, setIsDeleting] = useState(false);
  const [ibanTimestamp, setIbanTimestamp] = useState('');
  const [shouldBeUndisplayed, setShouldBeUndisplayed] = useState(true);
  const customerContext = useCustomerData();
  const ibanContext = useIbanStatus();
  const authContext = useAuth();
  const context = useDataContext();
  const { registerQuestion } = context;

  useEffect(() => {
    setIsLoading(true);
    registerQuestion(blockSlug, questionData);
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getQuestionTitle = () => {
    const { fields } = questionData.instances[0];
    const titles = questionData.title.split('\n');
    const name = titles[0];
    let subtitle;
    if (titles.length > 1) {
      if (titles[1].includes('zusätzlich')) {
        const subtitleTexts = titles[1].split('zusätzlich');
        subtitle = (
          <>
            {subtitleTexts[0]}
            <span className={styles.underlineSubtitle}>zusätzlich</span>
            {subtitleTexts[1]}
          </>
        );
      } else {
        // eslint-disable-next-line prefer-destructuring
        subtitle = titles[1];
      }
    }
    const hiddenFields = (customerContext.hiddenFields?.loanProcess || []).map((item: string) =>
      item.toLowerCase(),
    );
    if (hiddenFields.includes(fields[0]?.slug)) {
      return null;
    }
    if (questionData.slug.startsWith('dropdown_balancing')) {
      if (name.includes('Muster-Gewinnermittlung')) {
        const formattedName = replaceWithNode(
          'Muster-Gewinnermittlung',
          name,
          <SimpleLink
            link={getLinkDocument('', 'EUER_Gewinnermittlung_Kontennachweis')}
            text="Muster-Gewinnermittlung"
          />,
        );
        return { title: fields[0].is_required ? <>{formattedName} *</> : formattedName, subtitle };
      }
    }
    if (
      questionData.slug.startsWith('q_letzte_gew_ein') ||
      questionData.slug.startsWith('q_letzte_bilanz_hgb')
    ) {
      let formattedName: any = name;
      if (name.includes('nicht älter als 15 Monate')) {
        formattedName = underlineWord('nicht älter als 15 Monate', name);
      }
      if (name.includes('Vorjahres')) {
        formattedName = underlineWord('Vorjahres', name);
      }
      return { title: fields[0].is_required ? <>{formattedName} *</> : formattedName, subtitle };
    }

    if (questionData.slug.startsWith('q_kredit') && name === 'Darlehen') {
      return { title: '', subtitle };
    }

    return { title: fields[0].is_required ? `${name} *` : name, subtitle };
  };

  const deleteButton = (index: any, questionInstance: any) =>
    !disabled && (
      <Button
        disabled={isDeleting}
        className={styles.deleteButton}
        size="small"
        onClick={deleteDuplicateQuestion(index, questionInstance)}
        startIcon={<Delete />}
      >
        Löschen
      </Button>
    );

  const deleteDuplicateQuestion =
    (index: number, questionInstance: QuestionInstance) => async () => {
      if (questionData.instances.length > 0) {
        if (QUESTION_SONSTINGE_DOKUMENTS.includes(questionData.slug)) {
          await removeFile(questionInstance);
        }
        if (questionData.instances.length < 2) {
          customerContext.setSonstigeDisplay(false);
          setShouldBeUndisplayed(true);
          return;
        }
      }
      try {
        setIsDeleting(true);
        const response = await removeQuestion(index);
        const fieldsList = (response?.data as Question)?.instances?.map((i) => i.fields);
        context.updateBlockAfterDeletion(
          blockSlug,
          questionData.slug,
          fieldsList,
          questionInstance,
        );
        context.saveStepData.updateStepData(blockSlug.split('__').pop(), {
          questionData: response?.data,
        });

        const tempQuestionData = structuredClone(response?.data);
        tempQuestionData.instances.forEach((instance: QuestionInstance) => {
          instance.fields.forEach((field: QuestionField) => {
            if (
              instance.index >= index + 1 &&
              field.slug.includes('qualifikation_sonstige_dokumente')
            ) {
              const newSlug = `${field.slug.slice(0, 3)}${Number(field.slug.at(3)) + 1}${field.slug.slice(4)}`;
              field.value = form[newSlug];
            } else if (instance.index >= index + 1) {
              const newSlug = `${Number(field.slug.at(0)) + 1}${field.slug.slice(1)}`;
              field.value = form[newSlug];
            } else {
              field.value = form[field.slug];
            }
          });
        });

        setQuestionData(tempQuestionData);
      } catch (err) {
        console.error(err);
      } finally {
        setIsDeleting(false);
      }
    };

  const handleDeleteField = (tempBlockSlug: string, field: string) => {
    setForm((prevForm: any) => {
      const newForm = { ...prevForm };
      delete newForm[field];
      return newForm;
    });
    if (deleteField) {
      deleteField(tempBlockSlug, field);
    }
  };

  const duplicateQuestion = () => {
    if (questionData.instances.length >= questionData.max_duplicated_number!) {
      return;
    }
    if (QUESTION_SONSTINGE_DOKUMENTS.includes(questionData.slug)) {
      displayQuestion();
    }
    request
      .post(`${ApiCaptiq.LOAN_APPLICATION_URL}${loanId}/duplicate_section/`, {
        duplicated_path: questionData.duplicated_path,
        section_path: questionData.section_path,
      })
      .then((response) => {
        setQuestionData((prevData) => {
          const tempData = structuredClone(prevData);
          tempData.instances.forEach((instance: QuestionInstance) => {
            instance.fields.forEach((field: QuestionField) => {
              if (form?.[field.slug]) {
                field.value = form[field.slug];
              }
            });
          });
          tempData.instances.push(response.data.instances[response.data.instances.length - 1]);
          return tempData;
        });
      })
      .catch((err) => console.error(err));
  };

  const renderSepaData = (sepaData: { [x: string]: string }, timestamp: string | number | Date) => {
    setIbanTimestamp(timestamp ? format(new Date(timestamp), 'dd.MM.yyyy HH:mm:ss') : '');

    if (renderExtraValidationInfo) {
      const sepaDataKeys = Object.keys(sepaData ?? {}) ?? [];
      const extraValidationInfo = sepaDataKeys?.length ? (
        <Grid item xs={12}>
          <div className={styles.containerTitle}>
            <Typography className={styles.questionTitle}>IBAN Validierung</Typography>
          </div>
          {sepaDataKeys.map((el, index) => (
            <Grid container key={`${el}-${index}`}>
              <Grid item xs={1}>
                {el}
              </Grid>
              <Grid item xs={10}>
                {sepaData[el] === 'YES' ? (
                  <CheckCircleRounded color="primary" />
                ) : (
                  <CancelOutlined color="error" />
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>
      ) : null;
      renderExtraValidationInfo?.(extraValidationInfo);
    }
  };

  const getBicBankName = async (
    iban: string,
    tempBlockSlug: string,
    questionSlug: string,
    fieldValidator?: string,
  ) => {
    const { setIbanValidation } = ibanContext;
    try {
      iban = String(iban).replace(/\s/g, '');
      const { data } = await request.get(GET_BIC_BANK_NAME(iban));
      setIbanValidation(data.is_valid);
      if (!data.is_valid) {
        handleChange(tempBlockSlug, questionSlug, 'name_der_bank', '', null, fieldValidator);
        handleChange(tempBlockSlug, questionSlug, 'bic', '', null, fieldValidator);
      } else {
        handleChange(
          tempBlockSlug,
          questionSlug,
          'name_der_bank',
          data.bank_name,
          null,
          fieldValidator,
        );
        handleChange(tempBlockSlug, questionSlug, 'bic', data.bic, null, fieldValidator);
        handleChange(tempBlockSlug, questionSlug, 'empfanger_iban', iban, null, fieldValidator);
      }
      const sepaData = data.sepa_data ?? {};
      renderSepaData(sepaData, data.last_api_update);
    } catch (error) {
      console.error(error);
    }
  };

  const validateIban = (iban?: string) => {
    const formatValue = electronicFormatIBAN(iban);
    return formatValue && isValidIBAN(formatValue);
  };

  const hideQuestionAdmin = (question: Question) => {
    const role = authContext.getRole();
    return (
      question.instances.filter(
        (instance) =>
          instance.fields?.filter((f) => (f.is_admin_only ? isAdmin(role) : true)).length > 0,
      ).length === 0
    );
  };

  const handleOnBlur = async (
    tempBlockSlug: string,
    questionSlug: string,
    fieldSlug: string,
    value?: string,
    fieldValidator?: string,
  ) => {
    const { setIbanStatus, setIbanValidation } = ibanContext;
    setForm((prevForm: any) => ({ ...prevForm, [fieldSlug]: value }));
    onChange(tempBlockSlug, questionSlug, fieldSlug, value, null, fieldValidator);
    if (fieldValidator === 'IBAN') {
      if (validateIban(value)) {
        await getBicBankName(value!, tempBlockSlug, questionSlug, fieldValidator);
      } else {
        handleChange(tempBlockSlug, questionSlug, 'name_der_bank', '', null, fieldValidator);
        handleChange(tempBlockSlug, questionSlug, 'bic', '', null, fieldValidator);
        removeExtraValidation();
        setIbanValidation(value === '');
      }
      setIbanStatus(true);
    }
  };

  const removeExtraValidation = () => {
    setIbanTimestamp('');
    if (renderExtraValidationInfo) {
      renderExtraValidationInfo(null);
    }
  };

  const handleChange = (
    tempBlockSlug: string,
    questionSlug: string,
    fieldSlug: string,
    value: any,
    valueDisplay?: any,
    fieldValidator?: string,
    id?: any,
  ) => {
    const { setIbanStatus } = ibanContext;
    let formatValue = value;
    if (fieldValidator === 'IBAN') {
      formatValue = value.trim();
      if (formatValue !== '' && form[fieldSlug] !== undefined && formatValue !== form[fieldSlug])
        setIbanStatus(false);
    }
    setForm((prevForm: any) => ({ ...prevForm, [fieldSlug]: formatValue }));
    onChange(tempBlockSlug, questionSlug, fieldSlug, formatValue, valueDisplay, fieldValidator, id);
  };

  const getLinkDocument = (tempTitle: string, slug?: string) => {
    const { balancingForms } = customerContext;
    if (slug) {
      return balancingForms[slug].template;
    }
    const findDocument: any = Object.keys(balancingForms).reduce((result, doc) => {
      if (balancingForms[doc].text === tempTitle) result = balancingForms[doc];
      return result;
    }, {});
    return findDocument.template;
  };

  const mapQuestions = (questionInstance: QuestionInstance, index: number, minFields: number) => {
    const hasDeleteButton =
      questionData.can_be_duplicated && questionData.instances.length > minFields;

    return (
      <React.Fragment key={`${questionInstance.section_path}`}>
        <Grid
          container
          spacing={questionData.layout === 'H' ? 3 : 0}
          className={index > 0 ? styles.divider : ''}
        >
          {questionData.slug === 'q_kredit' && questionData.title === 'Darlehen' ? (
            <Typography className={styles.customTitle} mt={index ? 2 : 0}>
              {questionData.title} {index + 1}
            </Typography>
          ) : null}
          {questionInstance.fields.map((data) => (
            <Field
              key={data.slug}
              data={data}
              index={index}
              questionData={questionData}
              handleChange={handleChange}
              handleOnBlur={handleOnBlur}
              blockSlug={blockSlug}
              upperFormState={upperFormState}
              customColors={customColors}
              blockData={blockData}
              blockHelper={blockHelper}
              disabled={disabled}
              showCopyButton={showCopyButton}
              block_id={block_id}
              step_id={step_id}
              form={form}
              handleDeleteField={handleDeleteField}
              ibanTimestamp={ibanTimestamp}
              loanId={loanId}
              questions={questions}
              title={title}
              personID={personID}
              renderSepaData={renderSepaData}
              reviewCancelEdition={reviewCancelEdition}
              validateIban={validateIban}
              stepOrder={stepOrder}
            />
          ))}
          {(blockDeepSlug === 'dokumente_pro_aus_hgb_true' ||
            blockDeepSlug === 'dokumente_pro_aus_hgb_false') && (
            <Grid item xs={questionData.layout === 'H' ? 4 : 12}>
              <HelperTextFieldDocument
                link={getLinkDocument(questionData.title)}
                name={questionData.title}
                questionSlug={questionData.slug}
              />
            </Grid>
          )}
        </Grid>
        {hasDeleteButton && deleteButton(index, questionInstance)}
      </React.Fragment>
    );
  };

  const removeQuestion = async (index: number) => {
    try {
      const response = await request.post(
        `${ApiCaptiq.LOAN_APPLICATION_URL}${loanId}/remove_section/`,
        {
          duplicated_path: questionData.duplicated_path,
          section_path: questionData.section_path,
          index: index + 1,
        },
      );
      return response;
    } catch (err) {
      console.error(err);
    }
  };

  const displayQuestion = () => {
    customerContext.setSonstigeDisplay(true);
    setShouldBeUndisplayed(false);
  };

  const shouldDisplayHiddenButton = (instances: QuestionInstance[]) =>
    instances.some((i) => i.fields.some((f) => f.value !== ''));

  const removeFile = async (questionInstance: QuestionInstance) => {
    const index = questionData.instances.findIndex(
      (instance: QuestionInstance) => instance.index === questionInstance.index,
    );
    const newData: any = { ...questionData };
    const { slug, value_display, file_id } = questionData.instances[index].fields[0];
    if (file_id && value_display) {
      const formData = new FormData();
      formData.append('file_name', value_display);
      formData.append('slug', slug);
      formData.append('section_path', `${blockSlug}__${slug}`);
      formData.append('loan_application_id', loanId);
      formData.append('file_id', file_id);
      formData.append('file_source', 'FileUpload');
      try {
        setIsDeleting(true);
        await request.post(`${ApiCaptiq.FILE_UPLOAD_URL}remove_file/`, formData, {
          headers: { 'content-type': 'multipart/form-data' },
        });
        newData.instances[index].fields[0].file_source = null;
        newData.instances[index].fields[0].value_display = '';
        newData.instances[index].fields[0].file_id = null;
        newData.instances[index].fields[0].linked_db_id = null;
        newData.instances[index].fields[0].value = '';
        handleChange(blockSlug, questionData.slug, slug, '', '');
      } catch (err) {
        console.error(err);
      } finally {
        setIsDeleting(false);
      }
    }
  };

  const { hiddenFields } = customerContext;
  if (!questionData || !questionData.instances) {
    return <LoadingIndicator />;
  }
  if (
    (upperFormState &&
      questionData.depending_field &&
      questionData.visibility_value &&
      questionData.visibility_value !== String(upperFormState[questionData.depending_field])) ||
    shouldHideQuestion(hiddenFields, questionData)
  ) {
    return null;
  }

  if (hideQuestionAdmin(questionData)) {
    // Note: Admin related fields have data that should be saved, but the fields won't be visible
    return (
      <>
        {questionData.instances.map((questionInstance, index) => (
          <>
            {questionInstance.fields.map((data) => (
              <Field
                key={data.slug}
                data={data}
                index={index}
                questionData={questionData}
                handleChange={handleChange}
                handleOnBlur={handleOnBlur}
                blockSlug={blockSlug}
                upperFormState={upperFormState}
                customColors={customColors}
                blockData={blockData}
                blockHelper={blockHelper}
                disabled={disabled}
                showCopyButton={showCopyButton}
                block_id={block_id}
                step_id={step_id}
                form={form}
                handleDeleteField={handleDeleteField}
                ibanTimestamp={ibanTimestamp}
                loanId={loanId}
                questions={questions}
                title={title}
                personID={personID}
                renderSepaData={renderSepaData}
                reviewCancelEdition={reviewCancelEdition}
                validateIban={validateIban}
                stepOrder={stepOrder}
              />
            ))}
          </>
        ))}
      </>
    );
  }

  if (shouldBeUndisplayed && QUESTION_SONSTINGE_DOKUMENTS.includes(questionData.slug)) {
    if (!(customerContext.sonstigeDisplay && shouldDisplayHiddenButton(questionData.instances))) {
      return (
        !disabled && (
          <Grid item key={questionData.section_path} xs={12} md={12}>
            <Button
              style={{
                marginTop: 10,
                color: customColors ? customColors.buttonColor : 'inherit',
              }}
              size="small"
              color="primary"
              onClick={displayQuestion}
            >
              <Add /> Weitere Nachweise hochladen (optional)
            </Button>
          </Grid>
        )
      );
    }
  }

  const disabledButton = questionData.max_duplicated_number
    ? questionData.max_duplicated_number <= questionData.instances.length
    : false;
  const questionTitle = getQuestionTitle();

  if (isLoading) {
    return null;
  }

  return (
    <Grid item xs={12} md={questionData.num_column ? questionData.num_column : 12}>
      <div
        className={
          questionData.can_be_duplicated || questionData.num_column ? styles.blockOutlined : ''
        }
      >
        {questionTitle?.title || questionTitle?.subtitle ? (
          <div className={styles.containerTitle}>
            {questionTitle.title ? (
              <Typography className={styles.questionTitle}>{questionTitle.title}</Typography>
            ) : null}
            {questionTitle.subtitle ? (
              <Typography className={styles.questionSubtitle}>{questionTitle.subtitle}</Typography>
            ) : null}
          </div>
        ) : null}
        {questionData.instances.map((questionInstance: QuestionInstance, index: number) =>
          mapQuestions(
            questionInstance,
            index,
            QUESTION_SONSTINGE_DOKUMENTS.includes(questionData.slug) ? 0 : 1,
          ),
        )}
      </div>
      {questionData.can_be_duplicated && !disabled && (
        <Button
          disabled={disabledButton}
          style={{ marginTop: 10, color: customColors ? customColors.buttonColor : 'inherit' }}
          size="small"
          color="primary"
          onClick={() => duplicateQuestion()}
        >
          <Add />{' '}
          {QUESTION_SONSTINGE_DOKUMENTS.includes(questionData.slug)
            ? 'Weitere Nachweise hochladen (optional)'
            : 'Weitere'}
        </Button>
      )}
    </Grid>
  );
});

CaptiqQuestions.displayName = 'CaptiqQuestions';

export default CaptiqQuestions;
