import SvgIcon from '@mui/material/SvgIcon';

export default function HelpIcon(props) {
  const { iconColor, className } = props;
  return (
    <SvgIcon viewBox="0 0 25 22" className={className}>
      <g id="Assets" stroke="none" strokeWidth="1">
        <g id="Filled-fields" transform="translate(-998.000000, -50.000000)">
          <g id="question" transform="translate(998.000000, 50.000000)">
            <path
              style={{ fill: iconColor || 'black' }}
              d="M10.5326686,14.6666667 C9.77781215,14.6666667 9.16666667,15.2916667 9.16666667,16.0416667 C9.16666667,16.7737876 9.75981605,17.4166667 10.5326686,17.4166667 C11.3055212,17.4166667 11.9166667,16.7738388 11.9166667,16.0416667 C11.9166667,15.2916667 11.2876282,14.6666667 10.5326686,14.6666667 Z"
              id="Path"
            />
            <path
              style={{ fill: iconColor || 'black' }}
              d="M10.8926662,6.41666667 C9.08169249,6.41666667 8.25,7.55801045 8.25,8.32844308 C8.25,8.88489166 8.69265082,9.1416889 9.05486866,9.1416889 C9.77922737,9.1416889 9.48412682,8.04311737 10.8524112,8.04311737 C11.5231608,8.04311737 12.0597527,8.35701249 12.0597527,9.01329028 C12.0597527,9.78376385 11.3085317,10.2260167 10.8658424,10.6254972 C10.4768392,10.9821646 9.96707111,11.5671416 9.96707111,12.7941118 C9.96707111,13.535975 10.1548379,13.75 10.704861,13.75 C11.3621794,13.75 11.4962985,13.4361458 11.4962985,13.1650639 C11.4962985,12.4231597 11.5097681,11.9951507 12.247558,11.3816861 C12.6097373,11.0820757 13.75,10.1119027 13.75,8.77077775 C13.75,7.42965276 12.6097373,6.41666667 10.8926662,6.41666667 Z"
              id="Path"
            />
            <path
              style={{ fill: iconColor || 'black' }}
              d="M11,0 C4.92060938,0 0,4.91979297 0,11 L0,21.140625 C0,21.6152578 0.384742187,22 0.859375,22 L11,22 C17.0793477,22 22,17.080207 22,11 C22,4.92060938 17.080207,0 11,0 Z M11,20.28125 L1.71875,20.28125 L1.71875,11 C1.71875,5.87051953 5.86983203,1.71875 11,1.71875 C16.1294805,1.71875 20.28125,5.86983203 20.28125,11 C20.28125,16.1294805 16.130168,20.28125 11,20.28125 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
