import { Component, useContext, memo, createRef } from 'react';
import moment from 'moment';
import validator from 'validator';
import _get from 'lodash/get';
import Lottie from 'lottie-react-web';
import { format } from 'date-fns';

import { TextField, FormControlLabel, InputAdornment } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import validatePhoneNumber from '../../utils/dataHandlers/validatePhoneNumber';
import CheckAnimation from '../../animation/check.json';
import UploadField from '../UploadField';
import { EXISTING } from '../../context/customer-context/constants';
import { useCustomerData } from '../../context/customer-context';
import { useChangeLFC } from '../../context/changelf-context';
import { LEGAL_FORM_SLUG } from '../../constants/defaults';
import { DataContext } from '../../context/data-context';
import { useEditorData } from '../../context/editor-context';
import { useBlockData } from '../../context/block-context';
import getCountryCode from '../../utils/getCountryPrefix';
import InputRender from './InputRender';
import PreviewField from '../PreviewField';
import { isValueValid } from '../../utils/utils';
import CaptiqCheckbox from '../CaptiqCheckbox/CaptiqCheckbox';
import { GenericFieldWrapper, UploadFieldWrapper } from './wrappers';
import { ContainedCopyButton } from '../CopyButton';
import styles from './styles';
import { CaptiqFieldPropTypes, valueFactory } from './utils';

class CaptiqField extends Component {
  constructor(props) {
    super(props);
    this.fieldRef = createRef();
    const { data, stepOrder, blockSlug, questionSlug } = this.props;

    let default_value = valueFactory(data);

    this.state = {
      value: default_value,
      stepOrder,
      hasValue: isValueValid(data.value),
      valueDisplay: data.value_display,
      datafileName: null,
      idTemp: null,
      fileSource: null,
    };

    if (data.field_type === 'DATE' && default_value) {
      default_value = moment(default_value).format('YYYY-MM-DD');
    }

    this.props.onChange(
      blockSlug,
      questionSlug,
      data.slug,
      default_value,
      null,
      data.field_validator,
    );

    this.commonProps = {
      id: data.slug,
      name: data.slug,
      key: data.slug,
      fullWidth: true,
      margin: 'dense',
      variant: 'outlined',
      placeholder: data.placeholder,
    };

    this.timer = null;
  }

  componentDidMount() {
    const { data: fieldData, blockSlug, questionSlug } = this.props;
    this.props.dataContext.registerField(blockSlug, questionSlug, fieldData);
    this.focusOnField(this.props.blockContext);
    if (this.props.validateAtBeginning) {
      this.checkOnBlur();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.data !== nextProps.data) {
      const default_value = valueFactory(nextProps.data);
      if (default_value !== this.state.value) {
        this.setState({ value: default_value });
      }
    }
    const nextValue = _get(
      nextProps,
      `dataContext.blocks[${nextProps?.blockSlug}].questions[${nextProps?.questionSlug}].fields[${nextProps?.data?.slug}].value`,
    );
    if (nextValue !== this.state.value) {
      if (nextProps.type === 'FILE') {
        const nextValueDisplay = nextProps.data.value_display;
        this.setState({ valueDisplay: nextValueDisplay });
      }
    }
    this.focusOnField(nextProps.blockContext);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.data.slug === 'telefonnummer_business') {
      return true;
    }
    if (nextState !== this.state) {
      return this.state.value !== nextState.value;
    }
    if (nextProps !== this.props) {
      const { data, blockSlug, questionSlug } = this.props;
      return (
        this.props.disabled !== nextProps.disabled ||
        this.props.calculationValue !== nextProps.calculationValue ||
        _get(
          this.props,
          `dataContext.blocks[${blockSlug}].questions[${questionSlug}].fields[${data?.slug}].value`,
        ) !== this.state.value
      );
    }
    return !(nextProps === this.props && nextState === this.state);
  }

  componentDidUpdate(prevProps, prevState) {
    const { data, blockSlug, questionSlug, type, onChange } = this.props;

    if (type === 'RICH_TEXT') {
      if (
        this.props.dataContext.blocks[blockSlug]?.questions &&
        prevState.value !==
          this.props.dataContext.blocks[blockSlug].questions[questionSlug].fields[data.slug].value
      )
        this.setState({
          value:
            this.props.dataContext.blocks[blockSlug].questions[questionSlug].fields[data.slug]
              .value,
        });
    }

    if (prevProps.data.value !== data.value) {
      onChange(blockSlug, questionSlug, data.slug, data.value, null, data.field_validator);
    }
  }

  componentWillUnmount() {
    const { blockSlug, data, questionSlug, reviewCancelEdition } = this.props;

    if (this.props.onChange && this.props.type === 'FILE' && blockSlug) {
      const regex = /^(?=.*darlehensantragsteller)(?=.*qualifikation).*$/;
      if (regex.test(blockSlug)) {
        this.props.onChange(blockSlug, questionSlug, data.slug, '', '', data.field_validator, '');
      }
    }

    if (this.props.dataContext.blocks[blockSlug] && !reviewCancelEdition) {
      this.props.dataContext.removeField(blockSlug, questionSlug, data.slug);
    }

    if (this.props.stepOrder === this.state.stepOrder && !reviewCancelEdition) {
      this.props.deleteField(blockSlug, data.slug);
    }
  }

  focusOnField(blockContext) {
    const { currentFieldSlug, setCurrentFieldSlug } = blockContext;
    const { data } = this.props;

    if (this.fieldRef.current && data.slug === currentFieldSlug) {
      if (this.props.type === 'RICH_TEXT') {
        setTimeout(() => {
          this.fieldRef.current.scrollIntoView();
        }, 100);
      }
      if (this.props.type === 'FILE' || this.props.type === 'RICH_TEXT') {
        this.fieldRef.current.click();
      }
      setTimeout(() => {
        this?.fieldRef?.current?.focus();
      }, 100);
      setCurrentFieldSlug('');
    }
  }

  changeValue(value, id) {
    const { blockSlug, questionSlug, data } = this.props;
    const { valueDisplay } = this.state;
    this.setState({ value });
    this.preventNavigation();

    this.props.onChange(
      blockSlug,
      questionSlug,
      data.slug,
      value,
      valueDisplay,
      data.field_validator,
      id,
    );
  }

  // TODO: Check if value update on context can be done here to improve speed and avoid setTimeout use.
  checkOnBlur = () => {
    const { blockSlug, questionSlug, data, type } = this.props;
    const { value, valueDisplay } = this.state;
    this.setState({ hasValue: isValueValid(value) });
    if (type === 'DECIMAL' || type === 'CHAR') {
      this.props.onChange(
        blockSlug,
        questionSlug,
        data.slug,
        value,
        valueDisplay,
        data.field_validator,
      );
    }
    if (!!this.props.onBlur && data.field_validator === 'IBAN') {
      this.props.onBlur(blockSlug, questionSlug, data.slug, value, data.field_validator);
    }
    this.forceUpdate();
  };

  copyToClipboard = (value) => {
    navigator.clipboard.writeText(value);
  };

  fieldFactory() {
    const {
      data,
      disabled,
      type,
      calculationValue,
      classes,
      onChange,
      questions,
      blockSlug,
      loanId,
      title,
      customColors,
      maxDate,
      personID,
      isIBAN,
      validateIban,
      upperFormState,
      customerContext,
    } = this.props;
    const { countryList } = customerContext;
    const { value, hasValue, valueDisplay, datafileName, idTemp, fileSource } = this.state;
    const showPlaceHolder = !disabled;
    this.commonProps = {
      ...this.commonProps,
      placeholder: showPlaceHolder ? data.placeholder : '',
    };

    const inputProps = {
      inputProps: {
        readOnly: data.is_readonly || disabled,
        maxLength: data.max_length,
      },
    };

    let dateVal = value;
    if (value && type === 'DATE') {
      dateVal = moment(value).format('DD.MM.YYYY');
    }

    switch (type) {
      case 'CHOICE':
        return InputRender.getChoice(
          data,
          value,
          inputProps,
          this.commonProps,
          this.handleChangeChoice,
          disabled,
          this.fieldRef,
        );
      case 'INTEGER':
        return (
          <TextField
            inputRef={this.fieldRef}
            className={value && classes.completeField}
            onBlur={this.checkOnBlur}
            {...this.commonProps}
            InputProps={{
              ...inputProps,
              endAdornment:
                value && hasValue ? (
                  <InputAdornment position="end">
                    <Lottie
                      width={30}
                      height={30}
                      options={{
                        animationData: CheckAnimation,
                        loop: false,
                      }}
                      speed={2}
                    />
                  </InputAdornment>
                ) : null,
            }}
            value={value}
            onChange={this.handleChange}
          />
        );
      case 'DECIMAL':
        return InputRender.getDecimalInput(
          data,
          value,
          disabled,
          inputProps,
          this.commonProps,
          this.handleChange,
          this.handleCurrencyChange,
          classes,
          hasValue,
          this.checkOnBlur,
          this.fieldRef,
        );
      case 'CHAR':
        return InputRender.getCharField(
          data,
          value,
          inputProps,
          this.commonProps,
          this.handleChange,
          classes,
          hasValue,
          this.checkOnBlur,
          disabled,
          this.fieldRef,
          isIBAN,
          validateIban,
          upperFormState,
          countryList,
        );
      case 'TEXT':
        inputProps.className = classes.multiline;
        return (
          <TextField
            inputRef={this.fieldRef}
            className={value && classes.completeField}
            onBlur={this.checkOnBlur}
            {...this.commonProps}
            InputProps={{
              ...inputProps,
              endAdornment: value && hasValue && (
                <InputAdornment>
                  <Lottie
                    width={30}
                    height={30}
                    options={{
                      animationData: CheckAnimation,
                      loop: false,
                    }}
                    speed={2}
                  />
                </InputAdornment>
              ),
            }}
            value={value}
            onChange={this.handleChange}
            multiline
            disabled={disabled}
          />
        );
      case 'RICH_TEXT':
        this.props.editorContext.addBlock(
          blockSlug,
          data.slug,
          value,
          questions,
          onChange,
          loanId,
          title,
          customColors,
          disabled,
        );

        return (
          <PreviewField
            value={value}
            blockSlug={blockSlug}
            slug={data.slug}
            innerRef={this.fieldRef}
          />
        );
      case 'DATE':
        return InputRender.getDatePicker(
          data,
          dateVal,
          disabled,
          this.handleDateChange,
          classes,
          maxDate,
          this.fieldRef,
        );
      case 'BOOLEAN':
        return (
          <FormControlLabel
            inputRef={this.fieldRef}
            key={data.slug}
            disabled={disabled}
            control={
              <CaptiqCheckbox
                className={
                  data.field_mask === 'HTML' ? classes.checkBoxFieldHtml : classes.checkBoxField
                }
                color="primary"
                name={data.slug}
                checked={value}
                onChange={this.handleChangeSwitch}
              />
            }
            label={data.label}
          />
        );
      case 'FILE':
        return (
          <UploadFieldWrapper>
            <UploadField
              key={data.slug}
              disabled={disabled || data.is_readonly}
              value={value}
              filename={data.is_readonly ? data.label : valueDisplay}
              onFilesAdded={this.handleFileAdd}
              customColors={customColors}
              size={data.size}
              accept={data.accept}
              inputRef={this.fieldRef}
              slug={data.slug}
              sectionPath={`${blockSlug}__${data.slug}`}
              loanId={`${loanId}`}
              datafileName={datafileName}
              category={data.category}
              fileType={data.file_type}
              fileId={data.file_id}
              fileIdNew={idTemp}
              linkedDbId={data.linked_db_id}
              fileSource={data.file_source || fileSource}
              personID={personID}
              legalForm={customerContext.legalForm}
              hgbAccordance={customerContext.hgbAccordance}
            />
            {value && (
              <div className={classes.marginCheckIcon}>
                <Lottie
                  width={30}
                  height={30}
                  options={{
                    animationData: CheckAnimation,
                    loop: false,
                  }}
                  speed={2}
                />
              </div>
            )}
          </UploadFieldWrapper>
        );
      case 'CALCULATED':
        return InputRender.getCalculated(data, calculationValue, classes.calculatedField);
      default:
        return (
          <TextField
            inputRef={this.fieldRef}
            className={value && classes.completeField}
            onBlur={this.checkOnBlur}
            {...this.commonProps}
            InputProps={{
              ...inputProps,
              endAdornment: value && hasValue && (
                <InputAdornment>
                  <Lottie
                    width={30}
                    height={30}
                    options={{
                      animationData: CheckAnimation,
                      loop: false,
                    }}
                    speed={2}
                  />
                </InputAdornment>
              ),
            }}
            value={value}
            onChange={this.handleChange}
          />
        );
    }
  }

  checkValueInteger = (event) => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === '' || re.test(event.target.value))
      this.changeValue(event.target.value);
  };

  handleChange = (event) => {
    // TODO: Check if the condition is needed (this.props.data.formula === "")
    if (this.props.type === 'INTEGER') this.checkValueInteger(event);
    else if (this.props.isIBAN) this.changeValue(event.target.value?.toUpperCase());
    else this.changeValue(event.target.value);
  };

  handleChangeChoice = (event) => {
    const {
      data,
      customerContext: { mainCompany },
    } = this.props;
    if (data.slug === LEGAL_FORM_SLUG && mainCompany?.status === EXISTING) {
      this.handleChangeLF(event);
    } else this.handleChangeOnBlur(event);
  };

  handleChangeOnBlur = (event) => {
    if (event) {
      const {
        data,
        customerContext: { setLegalForm },
      } = this.props;
      if (data.slug === LEGAL_FORM_SLUG) {
        setLegalForm(event.target.value);
      }
      this.setState({ hasValue: !!event.target.value });
      this.handleChange(event);
    }
  };

  handleChangeLF = (event) => {
    const { value } = event.target;
    const {
      changeLFContext: { setWantedLF, setOpenChangeLF, setHandleAccept, setWantedLFEvent },
    } = this.props;
    const handleChangeAccept = this.handleChangeOnBlur.bind(this);
    setWantedLF(value);
    setOpenChangeLF(true);
    setHandleAccept(() => handleChangeAccept);
    setWantedLFEvent(event);
  };

  handleChangeSwitch = (event) => {
    this.changeValue(event.target.checked);
  };

  handleCurrencyChange = (event) => {
    if (this.props.data.formula === '') {
      if (event.target.value === '') {
        this.changeValue('');
      } else this.changeValue(Number(event.target.value.replace(',', '.')));
    }
  };

  handleDateChange = (date) => {
    const { blockSlug, questionSlug, data } = this.props;
    this.setState({ value: date });
    if (date) {
      this.setState({ hasValue: true });
      this.props.onChange(blockSlug, questionSlug, data.slug, format(date, 'yyyy-MM-dd'));
    } else {
      this.setState({ hasValue: false });
      this.props.onChange(blockSlug, questionSlug, data.slug, date, null, data.field_validator);
    }
    this.preventNavigation();
  };

  handleFileAdd = (value, valueDisplay = null, datafileName = null, idTemp = null, fileSource) => {
    this.setState({ valueDisplay, datafileName, idTemp, fileSource }, () => {
      this.changeValue(value, idTemp);
    });
  };

  preventNavigation() {
    if (!document.body.onbeforeunload) {
      document.body.onbeforeunload = () => true;
    }
  }

  validateValue(data, value) {
    let hasError = false;

    if (value && data.field_validator === 'EMAIL') {
      hasError = !validator.isEmail(value);
    }

    if (value && data.field_validator === 'PHONE') {
      const internationalPrefixCode = _get(this.props.upperFormState, `internationales_prafix`);
      const internationalPrefix = getCountryCode(
        this.customerContext.countryList,
        internationalPrefixCode,
      );
      const [phoneError] = validatePhoneNumber(value, String(internationalPrefix));
      hasError = phoneError;
    }

    return hasError;
  }

  render() {
    const { data, type, numColumn, showCopyButton, classes } = this.props;
    const displayLabel = data.label && type !== 'BOOLEAN' && !(type === 'FILE' && data.is_readonly);

    return (
      <>
        <GenericFieldWrapper
          displayLabel={displayLabel}
          type={type}
          data={data}
          numColumn={numColumn}
        >
          {data.is_readonly && type !== 'CALCULATED'
            ? InputRender.getCalculated(data, data.value, classes.readOnlyContainer)
            : this.fieldFactory()}
          {showCopyButton && <ContainedCopyButton value={this.state.value} field={data} />}
        </GenericFieldWrapper>
      </>
    );
  }
}

CaptiqField.propTypes = CaptiqFieldPropTypes;

CaptiqField.defaultProps = {
  onChange: () => {},
  reviewCancelEdition: false,
};

const areEqualProps = (prevProp, nextProp) => prevProp === nextProp;

const WrapperCaptiqField = memo((props) => {
  const dataContext = useContext(DataContext);
  const editorContext = useEditorData();
  const blockContext = useBlockData();
  const customerContext = useCustomerData();
  const changeLFContext = useChangeLFC();

  return (
    <CaptiqField
      {...props}
      dataContext={dataContext}
      editorContext={editorContext}
      blockContext={blockContext}
      customerContext={customerContext}
      changeLFContext={changeLFContext}
    />
  );
}, areEqualProps);

WrapperCaptiqField.displayName = 'WrapperCaptiqField';

export default withStyles(styles)(WrapperCaptiqField);
