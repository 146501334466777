import React, { useCallback, useMemo, memo, useState } from 'react';
import { useFormikContext, FormikErrors, Field } from 'formik';
import _get from 'lodash.get';

import { Grid, MenuItem, FormHelperText, InputAdornment } from '@mui/material';

import { LANGUAGE } from 'constants/language';
import { SALUTATIONS, getSalutationLabel } from 'constants/salutations';
import { TITLES, getTitleLabel } from 'constants/titles';
import { useCustomerData } from 'context/customer-context';
import { FastFieldProps, LegalFormsType, YesOrNo } from 'utils/commonTypes/types';
import CustomField from 'components/StaticBlocks/CustomField';
import {
  addRoleRefs,
  CLOCK_STATUS,
  deleteRoleRefs,
  getCurrentIconFunction,
  getLocaleAllocation,
  handleAllocationChangeFunction,
  getTotalShareValueWithParent,
  noSideDealsDisplayLabel,
} from 'utils/utils';
import {
  EKLegalFormLabel,
  FreelanceLegalFormLabel,
  GbRLegalForm,
  PartGLegalForm,
  PartGmbBLegalForm,
} from 'constants/legalForms';
import { Person, RefOwner, InitialValues } from 'context/customer-context/types';
import WarningMessage from 'components/WarningMessage';
import PercentageField from 'components/PercentageField';
import LabeledGrid from 'components/LabeledGrid';
import { useLazyEffect } from 'hooks/useLazyEffect';
import {
  useInterval,
  validateSharesCompanyFunction,
  validateSharesFunction,
} from 'utils/dataHandlers';
import { useDynamicTexts } from 'context/dynamic-texts-context';

interface ContentProps {
  currentTab: number;
  refOwner: RefOwner;
  isEditing?: boolean;
}

const Content = memo(({ refOwner, currentTab, isEditing = true }: ContentProps) => {
  const {
    legalForms,
    people,
    setRefsBeneficialOwners,
    refsBeneficialOwners,
    currentLegalForm,
    setRefsLegalRepresentatives,
    refsLegalRepresentatives,
    refsLoanApplicants,
    setRefsLoanApplicants,
    customerProfileId,
    mainCompany,
  } = useCustomerData();
  const { companyOrApplicantCompany } = useDynamicTexts();
  const [loadingAllocation, setLoadingAllocation] = useState(false);
  const [allocationStatus, setAllocationStatus] = useState<string | null>(null);
  const [timerCount, setTimerCount] = useState(0);
  const [showCheck, setShowCheck] = useState(CLOCK_STATUS.STOPPED);
  const { values, errors, validateField, setFieldValue, handleChange } =
    useFormikContext<InitialValues>();

  const { _type: personOrCompany, index: refIndex, id: ownerId } = refOwner;
  const legalFormsArray = useMemo(() => Object.entries(legalForms || {}), [legalForms]) as
    | LegalFormsType[]
    | [];
  const isEkOrFreelancer = useMemo(
    () =>
      currentLegalForm?.label === EKLegalFormLabel ||
      currentLegalForm?.label === FreelanceLegalFormLabel ||
      currentLegalForm?.short_label === EKLegalFormLabel ||
      currentLegalForm?.short_label === FreelanceLegalFormLabel,
    [currentLegalForm?.label, currentLegalForm?.short_label],
  );
  const notEkOrFreelancer = useMemo(
    () =>
      currentLegalForm?.label !== EKLegalFormLabel &&
      currentLegalForm?.label !== FreelanceLegalFormLabel,
    [currentLegalForm?.label],
  );
  const indexOfShare = useMemo(() => {
    const targetArray = personOrCompany === 'person' ? values.people : values.companies;

    if (['person', 'company'].includes(personOrCompany)) {
      const idx = targetArray[refIndex]?.shares?.findIndex(
        (shareTarget) => shareTarget.parent === mainCompany?.id,
      );
      return idx >= 0 ? idx : 0;
    }

    return 0;
  }, [personOrCompany, values.people, values.companies, refIndex, mainCompany]);
  const notGbR = useMemo(() => currentLegalForm?.label !== GbRLegalForm, [currentLegalForm?.label]);
  const notPartG = useMemo(
    () => currentLegalForm?.label !== PartGLegalForm,
    [currentLegalForm?.label],
  );
  const notPartGmbB = useMemo(
    () => currentLegalForm?.label !== PartGmbBLegalForm,
    [currentLegalForm?.label],
  );
  const share =
    personOrCompany === 'person'
      ? values.people[refIndex]?.shares[indexOfShare]
      : values.companies[refIndex]?.shares[indexOfShare];
  const personId = useMemo(() => values.people?.[refIndex]?.id, [values.people, refIndex]);
  const companyId = useMemo(() => values.companies?.[refIndex]?.id, [values.companies, refIndex]);
  const getCurrentIcon = useCallback(
    () => getCurrentIconFunction(loadingAllocation, allocationStatus),
    [loadingAllocation, allocationStatus],
  );

  useInterval(
    () => {
      if (timerCount > 0) {
        setTimerCount(timerCount - 1);
      } else {
        setShowCheck(CLOCK_STATUS.STOPPED);
      }
    },
    showCheck === CLOCK_STATUS.STARTED ? 2000 : null,
  );

  useLazyEffect(() => {
    if (
      personOrCompany === 'person' &&
      values?.people?.length > 0 &&
      values.people[refIndex] &&
      values.people[refIndex].shares &&
      values.people[refIndex].shares[indexOfShare]
    ) {
      validateField(`people[${refIndex}].shares[${indexOfShare}].allocation`);
      validateField(`people[${refIndex}].isLegalRepresentative`);
      validateField(`people[${refIndex}].isLoanApplicant`);
      validateField(`people[${refIndex}].isBeneficialOwnerLevelOne`);
    }
  }, [validateField, values.people, currentTab, refIndex, personOrCompany, indexOfShare]);

  const handlerMap = useCallback(
    ([key, data]: any) => <MenuItem value={key}>{data.label}</MenuItem>,
    [],
  );

  const validatePersonShareFunctionReusable = useCallback(
    (
      allocation: string,
      sideDealDirect?: YesOrNo,
      isBeneficialOwnerLevelOneDirect?: YesOrNo,
      totalShares?: number,
    ) =>
      validateSharesFunction(
        allocation,
        values.people[refIndex].shares[indexOfShare].sideDeal,
        values.company.data,
        values.people[refIndex],
        currentLegalForm,
        legalForms,
        sideDealDirect,
        isBeneficialOwnerLevelOneDirect,
        undefined,
        undefined,
        true,
        totalShares,
      ),
    [values.people, values.company.data, refIndex, indexOfShare, currentLegalForm, legalForms],
  );

  const validateCompanyShareFunctionReusable = useCallback(
    (
      allocation: string,
      sideDealDirect?: YesOrNo,
      isBeneficialOwnerLevelOneDirect?: YesOrNo,
      totalShares?: number,
    ) =>
      validateSharesCompanyFunction(
        allocation,
        values.companies[refIndex].shares[indexOfShare].sideDeal,
        values.company.data,
        values.companies[refIndex],
        legalForms,
        sideDealDirect,
        isBeneficialOwnerLevelOneDirect,
        undefined,
        undefined,
        true,
        totalShares,
      ),
    [values.companies, values.company.data, refIndex, indexOfShare, legalForms],
  );

  const handleCompanyAllocationChange = useCallback(
    async (allocation: string, sideDeal: YesOrNo | undefined) =>
      handleAllocationChangeFunction(
        customerProfileId,
        allocation,
        sideDeal,
        showCheck,
        share.id,
        'company',
        companyId,
        values.company.id,
        setLoadingAllocation,
        setShowCheck,
        setAllocationStatus,
      ),
    [share, values.company, showCheck, companyId, customerProfileId],
  );

  const getGeneralName = useCallback(
    (name: any) =>
      refOwner._type === 'person'
        ? `people[${refOwner.index}].${name}`
        : `companies[${refOwner.index}].${name}`,
    [refOwner._type, refOwner.index],
  );

  const validateShares = useCallback(
    (allocation: string) => {
      const totalState = getTotalShareValueWithParent(
        mainCompany?.id as number,
        values.people,
        values.companies,
      );
      if (personOrCompany === 'person') {
        return validatePersonShareFunctionReusable(allocation, undefined, undefined, totalState);
      }
      return validateCompanyShareFunctionReusable(allocation, undefined, undefined, totalState);
    },
    [
      personOrCompany,
      values.people,
      values.companies,
      validateCompanyShareFunctionReusable,
      validatePersonShareFunctionReusable,
      mainCompany,
    ],
  );

  const validateLegalRepresentative = useCallback(
    (isLegalRepresentative: YesOrNo) => {
      if (
        currentLegalForm &&
        (currentLegalForm.label === EKLegalFormLabel ||
          currentLegalForm.label === FreelanceLegalFormLabel ||
          currentLegalForm.label === GbRLegalForm) &&
        isLegalRepresentative === 'no'
      )
        return 'Must be LRP';
      return undefined;
    },
    [currentLegalForm],
  );

  const showLRPError = useCallback(() => {
    const poepleErrors = errors.people as FormikErrors<Person>[];
    const personIndex = refIndex;
    const errorPerson = poepleErrors && poepleErrors[personIndex];
    return (
      currentLegalForm &&
      (currentLegalForm.label === EKLegalFormLabel ||
        currentLegalForm.label === FreelanceLegalFormLabel ||
        currentLegalForm.label === GbRLegalForm) &&
      errorPerson &&
      errorPerson.isLegalRepresentative && (
        <FormHelperText error>
          Aufgrund der ausgewählten Rechtsform {values?.company?.data?.legalForm} muss die
          angegebene Person gesetzlicher Vertreter.
        </FormHelperText>
      )
    );
  }, [errors.people, refIndex, currentLegalForm, values.company]);

  const validateBO = useCallback(
    (isBeneficialOwner: YesOrNo) => {
      if (
        currentLegalForm &&
        (currentLegalForm.label === EKLegalFormLabel ||
          currentLegalForm.label === FreelanceLegalFormLabel) &&
        (isBeneficialOwner === 'no' || !isBeneficialOwner)
      )
        return 'Must be BO';
      return undefined;
    },
    [currentLegalForm],
  );

  const showBOError = useCallback(() => {
    const poepleErrors = errors.people as FormikErrors<Person>[];
    const personIndex = refIndex;
    const errorPerson = poepleErrors && poepleErrors[personIndex];
    return (
      currentLegalForm &&
      (currentLegalForm.label === EKLegalFormLabel ||
        currentLegalForm.label === FreelanceLegalFormLabel) &&
      errorPerson &&
      errorPerson.isBeneficialOwnerLevelOne && (
        <FormHelperText error>
          Aufgrund der ausgewählten Rechtsform {values?.company?.data?.legalForm} muss die
          angegebene Person wirtschaftlicher Berechtigter.
        </FormHelperText>
      )
    );
  }, [errors.people, refIndex, currentLegalForm, values?.company?.data?.legalForm]);

  const handlePersonAllocationChange = useCallback(
    async (allocation: string, sideDeal: YesOrNo) =>
      handleAllocationChangeFunction(
        customerProfileId,
        allocation,
        sideDeal,
        showCheck,
        share.id!,
        'person',
        personId,
        values.company.id,
        setLoadingAllocation,
        setShowCheck,
        setAllocationStatus,
      ),
    [share, values.company, showCheck, personId, customerProfileId],
  );

  const handleChangeBO = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      if (!value) return;
      if (personOrCompany === 'person') {
        let currentSideDeal: YesOrNo | undefined =
          values.people[refIndex].shares[indexOfShare].sideDeal;
        handleChange(event);
        if (value === 'yes') {
          setFieldValue(`people[${refIndex}].isBeneficialOwner`, value);
          if (
            values.people[refIndex].shares[indexOfShare].sideDeal === 'yes' &&
            values.people[refIndex].shares[indexOfShare].allocation
          )
            addRoleRefs('BO', refIndex, people, setRefsBeneficialOwners, refsBeneficialOwners);
        }
        if (value === 'no') {
          setFieldValue(`people[${refIndex}].shares[${indexOfShare}].sideDeal`, 'no');
          currentSideDeal = 'no';
          if (
            (!values.people[refIndex].isBeneficialOwnerLevelTwo ||
              values.people[refIndex].isBeneficialOwnerLevelTwo === 'no') &&
            (!values.people[refIndex].isBeneficialOwnerLevelThree ||
              values.people[refIndex].isBeneficialOwnerLevelThree === 'no')
          )
            setFieldValue(`people[${refIndex}].isBeneficialOwner`, 'no');
          const index = [...refsBeneficialOwners].findIndex(
            (beneficialOwner) => beneficialOwner.id === ownerId,
          );
          deleteRoleRefs('BO', index, refsBeneficialOwners, setRefsBeneficialOwners);
        }
        setFieldValue(`people[${refIndex}].isBeneficialOwnerLevelOne`, value);
        if (value === 'yes') {
          setFieldValue(`people[${refIndex}].isBeneficialOwner`, 'yes');
        } else if (
          (!values.people[refIndex].isBeneficialOwnerLevelTwo ||
            values.people[refIndex].isBeneficialOwnerLevelTwo === 'no') &&
          (!values.people[refIndex].isBeneficialOwnerLevelThree ||
            values.people[refIndex].isBeneficialOwnerLevelThree === 'no')
        )
          setFieldValue(`people[${refIndex}].isBeneficialOwner`, 'no');
        setTimeout(() => {
          validateField(`people[${refIndex}].isBeneficialOwnerLevelOne`);
          if (value === 'no') validateField(`people[${refIndex}].shares[${indexOfShare}].sideDeal`);
          setTimeout(() => {
            const totalState = getTotalShareValueWithParent(
              mainCompany?.id as number,
              values.people,
              values.companies,
            );
            if (
              !validatePersonShareFunctionReusable(
                (values.people[refIndex].shares[indexOfShare].allocation as string) || '',
                currentSideDeal,
                value as YesOrNo,
                totalState,
              ) &&
              currentSideDeal
            ) {
              handlePersonAllocationChange(
                values.people[refIndex].shares[indexOfShare].allocation as string,
                currentSideDeal,
              );
            }
          }, 0);
        }, 0);
      }
      if (personOrCompany === 'company') {
        let currentSideDeal: YesOrNo | undefined =
          values.companies[refIndex].shares[indexOfShare].sideDeal;
        setFieldValue(`companies[${refIndex}].isBeneficialOwnerLevelOne`, value);
        if (value === 'yes') {
          setFieldValue(`companies[${refIndex}].isBeneficialOwner`, 'yes');
          if (
            values.companies[refIndex].shares[indexOfShare].sideDeal === 'yes' &&
            values.companies[refIndex].shares[indexOfShare].allocation
          )
            addRoleRefs(
              'BO',
              refIndex,
              values.companies,
              setRefsBeneficialOwners,
              refsBeneficialOwners,
              'company',
            );
        }
        if (value === 'no') {
          setFieldValue(`companies[${refIndex}].shares[${indexOfShare}].sideDeal`, 'no');
          currentSideDeal = 'no';
          if (
            (!values.companies[refIndex].isBeneficialOwnerLevelTwo ||
              values.companies[refIndex].isBeneficialOwnerLevelTwo === 'no') &&
            (!values.companies[refIndex].isBeneficialOwnerLevelThree ||
              values.companies[refIndex].isBeneficialOwnerLevelThree === 'no')
          )
            setFieldValue(`companies[${refIndex}].isBeneficialOwner`, 'no');
          const index = [...refsBeneficialOwners].findIndex(
            (beneficialOwner) => beneficialOwner.id === ownerId,
          );
          deleteRoleRefs('BO', index, refsBeneficialOwners, setRefsBeneficialOwners);
          setFieldValue(`companies[${refIndex}].shares[${indexOfShare}].sideDeal`, 'no');
        }
        setTimeout(() => {
          validateField(`companies[${refIndex}].isBeneficialOwnerLevelOne`);
          if (value === 'no')
            validateField(`companies[${refIndex}].shares[${indexOfShare}].sideDeal`);
          setTimeout(() => {
            const totalState = getTotalShareValueWithParent(
              mainCompany?.id as number,
              values.people,
              values.companies,
            );
            if (
              !validateCompanyShareFunctionReusable(
                (values.companies[refIndex].shares[indexOfShare].allocation as string) || '',
                currentSideDeal,
                value as YesOrNo,
                totalState,
              ) &&
              currentSideDeal
            ) {
              handleCompanyAllocationChange(
                values.companies[refIndex].shares[indexOfShare].allocation as string,
                currentSideDeal,
              );
            }
          }, 0);
        }, 0);
      }
    },
    [
      personOrCompany,
      values.people,
      values.companies,
      refIndex,
      indexOfShare,
      handleChange,
      setFieldValue,
      people,
      setRefsBeneficialOwners,
      refsBeneficialOwners,
      ownerId,
      validateField,
      mainCompany?.id,
      validatePersonShareFunctionReusable,
      handlePersonAllocationChange,
      validateCompanyShareFunctionReusable,
      handleCompanyAllocationChange,
    ],
  );

  const handleBlurAllocation = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const currentAllocation = getLocaleAllocation(value, LANGUAGE);
      if (personOrCompany === 'person') {
        let currentSideDeal: YesOrNo | undefined =
          values.people[refIndex].shares[indexOfShare].sideDeal;
        let currentBO: YesOrNo = values.people[0].isBeneficialOwnerLevelOne;
        const newBO =
          currentAllocation > 25 && values.people[refIndex].shares[indexOfShare].sideDeal;
        const wasBO =
          currentAllocation <= 25 && values.people[refIndex].shares[indexOfShare].sideDeal === 'no';
        if (currentAllocation >= 50) {
          setFieldValue(`people[${refIndex}].shares[${indexOfShare}].sideDeal`, 'no');
          currentSideDeal = 'no';
        }
        if (newBO) {
          const alreadInRefs = refsBeneficialOwners.find((r) => r.id === ownerId);
          if (!alreadInRefs) {
            addRoleRefs(
              'BO',
              refIndex,
              values.people,
              setRefsBeneficialOwners,
              refsBeneficialOwners,
            );
            setFieldValue(`people[${refIndex}].isBeneficialOwnerLevelOne`, 'yes');
            setFieldValue(`people[${refIndex}].isBeneficialOwner`, 'yes');
            currentBO = 'yes';
          }
        }
        if (wasBO) {
          const index = [...refsBeneficialOwners].findIndex(
            (beneficialOwner) => beneficialOwner.id === ownerId,
          );
          deleteRoleRefs('BO', index, refsBeneficialOwners, setRefsBeneficialOwners);
          if (index !== -1) {
            currentSideDeal = 'no';
            setFieldValue(`people[${refIndex}].shares[${indexOfShare}].sideDeal`, 'no');
            setFieldValue(`people[${refIndex}].isBeneficialOwnerLevelOne`, 'no');
            currentBO = 'no';
            if (
              (!values.people[refIndex].isBeneficialOwnerLevelTwo ||
                values.people[refIndex].isBeneficialOwnerLevelTwo === 'no') &&
              (!values.people[refIndex].isBeneficialOwnerLevelThree ||
                values.people[refIndex].isBeneficialOwnerLevelThree === 'no')
            )
              setFieldValue(`people[${refIndex}].isBeneficialOwner`, 'no');
          }
        }
        setFieldValue(`people[${refIndex}].shares[${indexOfShare}].allocation`, value);
        setTimeout(() => {
          validateField(`people[${refIndex}].shares[${indexOfShare}].allocation`);
          if (newBO || wasBO) validateField(`people[${refIndex}].isBeneficialOwnerLevelOne`);
          setTimeout(() => {
            const totalState = getTotalShareValueWithParent(
              mainCompany?.id as number,
              values.people,
              values.companies,
            );
            if (
              !validatePersonShareFunctionReusable(value, currentSideDeal, currentBO, totalState) &&
              currentSideDeal
            ) {
              handlePersonAllocationChange(value, currentSideDeal);
            }
          }, 0);
        }, 0);
      }
      if (personOrCompany === 'company') {
        let currentSideDeal: YesOrNo | undefined =
          values.companies[refIndex].shares[indexOfShare].sideDeal;
        let currentBO: YesOrNo = values.companies[refIndex].isBeneficialOwnerLevelOne;
        const newBO =
          currentAllocation > 25 && values.companies[refIndex].shares[indexOfShare].sideDeal;
        const wasBO =
          currentAllocation <= 25 &&
          values.companies[refIndex].shares[indexOfShare].sideDeal === 'no';
        if (currentAllocation >= 50) {
          setFieldValue(`companies[${refIndex}].shares[${indexOfShare}].sideDeal`, 'no');
          currentSideDeal = 'no';
        }
        if (newBO) {
          const alreadInRefs = refsBeneficialOwners.find((r) => r.id === ownerId);
          if (!alreadInRefs) {
            addRoleRefs(
              'BO',
              refIndex,
              values.companies,
              setRefsBeneficialOwners,
              refsBeneficialOwners,
              'company',
            );
            setFieldValue(`companies[${refIndex}].isBeneficialOwnerLevelOne`, 'yes');
            setFieldValue(`companies[${refIndex}].isBeneficialOwner`, 'yes');
            currentBO = 'yes';
          }
        }
        if (wasBO) {
          const index = [...refsBeneficialOwners].findIndex(
            (beneficialOwner) => beneficialOwner.id === ownerId,
          );
          deleteRoleRefs('BO', index, refsBeneficialOwners, setRefsBeneficialOwners);
          if (index !== -1) {
            currentSideDeal = 'no';
            setFieldValue(`companies[${refIndex}].shares[${indexOfShare}].sideDeal`, 'no');
            setFieldValue(`companies[${refIndex}].isBeneficialOwnerLevelOne`, 'no');
            currentBO = 'no';
            if (
              (!values.companies[refIndex].isBeneficialOwnerLevelTwo ||
                values.companies[refIndex].isBeneficialOwnerLevelTwo === 'no') &&
              (!values.companies[refIndex].isBeneficialOwnerLevelThree ||
                values.companies[refIndex].isBeneficialOwnerLevelThree === 'no')
            )
              setFieldValue(`companies[${refIndex}].isBeneficialOwner`, 'no');
          }
        }
        setFieldValue(`companies[${refIndex}].shares[${indexOfShare}].allocation`, value);
        setTimeout(() => {
          validateField(`companies[${refIndex}].shares[${indexOfShare}].allocation`);
          if (newBO || wasBO) validateField(`companies[${refIndex}].isBeneficialOwnerLevelOne`);
          setTimeout(() => {
            const totalState = getTotalShareValueWithParent(
              mainCompany?.id as number,
              values.people,
              values.companies,
            );
            if (
              !validateCompanyShareFunctionReusable(value, currentSideDeal, currentBO, totalState)
            ) {
              handleCompanyAllocationChange(value, currentSideDeal);
            }
          }, 0);
        }, 0);
      }
    },
    [
      personOrCompany,
      values.people,
      values.companies,
      refIndex,
      indexOfShare,
      setFieldValue,
      refsBeneficialOwners,
      ownerId,
      setRefsBeneficialOwners,
      validateField,
      mainCompany?.id,
      validatePersonShareFunctionReusable,
      handlePersonAllocationChange,
      validateCompanyShareFunctionReusable,
      handleCompanyAllocationChange,
    ],
  );

  const handleChangeLP = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      if (!value) return;
      handleChange(event);
      if (value === 'yes')
        addRoleRefs('LR', refIndex, people, setRefsLegalRepresentatives, refsLegalRepresentatives);
      else {
        const index = [...refsLegalRepresentatives].findIndex(
          (legalRepresentative) => legalRepresentative.id === ownerId,
        );
        deleteRoleRefs('LR', index, refsLegalRepresentatives, setRefsLegalRepresentatives);
        if (values.people[refIndex].isLoanApplicant === 'yes') {
          const indexLA = [...refsLoanApplicants].findIndex(
            (loanApplicant) => loanApplicant.id === ownerId,
          );
          setFieldValue(`people[${refIndex}].isLoanApplicant`, 'no');
          deleteRoleRefs('LA', indexLA, refsLoanApplicants, setRefsLoanApplicants);
        }
      }
      setFieldValue(`people[${refIndex}].isLegalRepresentative`, value);
      setTimeout(() => {
        validateField(`people[${refIndex}].isLegalRepresentative`);
        validateField(`people[${refIndex}].isLoanApplicant`);
      }, 0);
    },
    [
      setRefsLegalRepresentatives,
      setRefsLoanApplicants,
      setFieldValue,
      ownerId,
      people,
      refIndex,
      refsLegalRepresentatives,
      refsLoanApplicants,
      validateField,
      values.people,
      handleChange,
    ],
  );

  const handleChangeSideDeals = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { target } = e;
      if (personOrCompany === 'person') {
        const realAllocation = values.people[refIndex].shares[indexOfShare].allocation;
        const currentAllocation = getLocaleAllocation(realAllocation, LANGUAGE);
        const isBO = target.value === 'yes' && realAllocation;
        const wasBO = target.value === 'no' && realAllocation && currentAllocation <= 25;
        if (!target.value) return;
        if (isBO) {
          const alreadInRefs = refsBeneficialOwners.find((r) => r.id === ownerId);
          if (!alreadInRefs)
            addRoleRefs(
              'BO',
              refIndex,
              values.people,
              setRefsBeneficialOwners,
              refsBeneficialOwners,
            );
        } else if (wasBO) {
          const indexDeleteBO = [...refsBeneficialOwners].findIndex(
            (beneficialOwner) => beneficialOwner.id === ownerId,
          );
          deleteRoleRefs('BO', indexDeleteBO, refsBeneficialOwners, setRefsBeneficialOwners);
        }
        setFieldValue(`people[${refIndex}].shares[${indexOfShare}].sideDeal`, target.value);
        setTimeout(() => {
          validateField(`people[${refIndex}].shares[${indexOfShare}].sideDeal`);
          if (wasBO || isBO) validateField(`people[${refIndex}].isBeneficialOwnerLevelOne`);
          setTimeout(() => {
            const totalState = getTotalShareValueWithParent(
              mainCompany?.id as number,
              values.people,
              values.companies,
            );
            if (
              !validatePersonShareFunctionReusable(
                (values.people[refIndex].shares[indexOfShare].allocation as string) || '',
                target.value as YesOrNo,
                undefined,
                totalState,
              )
            ) {
              handlePersonAllocationChange(
                values.people[refIndex].shares[indexOfShare].allocation as string,
                target.value as YesOrNo,
              );
            }
          }, 0);
        }, 0);
      }
      if (personOrCompany === 'company') {
        const realAllocation = values.companies[refIndex].shares[indexOfShare].allocation;
        const currentAllocation = getLocaleAllocation(realAllocation, LANGUAGE);
        const isBO = target.value === 'yes' && realAllocation;
        const wasBO = target.value === 'no' && realAllocation && currentAllocation <= 25;
        if (!target.value) return;
        if (isBO) {
          const alreadInRefs = refsBeneficialOwners.find((r) => r.id === ownerId);
          if (!alreadInRefs)
            addRoleRefs(
              'BO',
              refIndex,
              values.companies,
              setRefsBeneficialOwners,
              refsBeneficialOwners,
              'company',
            );
        } else if (wasBO) {
          const index = [...refsBeneficialOwners].findIndex(
            (beneficialOwner) => beneficialOwner.id === ownerId,
          );
          deleteRoleRefs('BO', index, refsBeneficialOwners, setRefsBeneficialOwners);
        }
        setFieldValue(`companies[${refIndex}].shares[${indexOfShare}].sideDeal`, target.value);
        setTimeout(() => {
          validateField(`companies[${refIndex}].shares[${indexOfShare}].sideDeal`);
          if (wasBO || isBO) validateField(`companies[${refIndex}].isBeneficialOwnerLevelOne`);
        }, 0);
      }
    },
    [
      personOrCompany,
      values.people,
      values.companies,
      refIndex,
      indexOfShare,
      setFieldValue,
      refsBeneficialOwners,
      setRefsBeneficialOwners,
      ownerId,
      validateField,
      mainCompany?.id,
      validatePersonShareFunctionReusable,
      handlePersonAllocationChange,
    ],
  );

  const handleUpdateBO = useCallback(
    (nextProps: FastFieldProps, currentProps: FastFieldProps): boolean => {
      const newFormikValue = nextProps.formik.values;
      const currentFormikValue = currentProps.formik.values;
      const newAllocation =
        personOrCompany === 'person' ? newFormikValue.people : newFormikValue.companies;
      const currentAllocation =
        personOrCompany === 'person' ? currentFormikValue.people : currentFormikValue.companies;
      if (
        getLocaleAllocation(newAllocation[refIndex].shares[indexOfShare].allocation, LANGUAGE) !==
        getLocaleAllocation(currentAllocation[refIndex].shares[indexOfShare].allocation, LANGUAGE)
      )
        return true;

      return false;
    },
    [personOrCompany, refIndex, indexOfShare],
  );

  const getFields = useCallback(() => {
    switch (personOrCompany) {
      case 'person':
        return (
          <>
            <CustomField
              data={SALUTATIONS}
              getLabel={getSalutationLabel}
              label="Bitte wählen"
              name={getGeneralName('salutation')}
              required
              title="Anrede"
              type="select"
              disabled={!isEditing}
            />
            <CustomField
              data={TITLES}
              getLabel={getTitleLabel}
              label="Bitte wählen"
              name={getGeneralName('title')}
              title="Titel"
              type="select"
              disabled={!isEditing}
            />
            <CustomField
              name={getGeneralName('firstNames')}
              title="Vorname(n)"
              type="text"
              disabled={!isEditing}
              required
            />
            <CustomField
              name={getGeneralName('lastName')}
              title="Nachname"
              type="text"
              disabled={!isEditing}
              required
            />
            <CustomField
              name={getGeneralName('dateOfBirth')}
              title="Geburtsdatum"
              type="date"
              disabled={!isEditing}
              maxDate={new Date()}
              required
            />
            <CustomField
              title="Geburtsland"
              name={getGeneralName('countryOfBirth')}
              textFieldProps={{ label: 'Bitte wählen' }}
              type="place"
              placetype="country"
              disabled={!isEditing}
              required
            />
            <CustomField
              title="Geburtsort"
              name={getGeneralName('cityOfBirth')}
              type="text"
              disabled={!isEditing}
              required
            />
            <CustomField
              title="Staatsangehörigkeit"
              name={getGeneralName('nationality')}
              textFieldProps={{ label: 'Bitte wählen' }}
              type="place"
              placetype="nationality"
              disabled={!isEditing}
              required
            />
            {_get(values, getGeneralName('taxableInUSA')) === 'yes' && (
              <WarningMessage
                message="Aufgrund Ihrer Angaben zur Steuerpflicht in den USA kann CAPTIQ Ihnen leider keine Finanzierung anbieten. Das Anlegen eines Kundenprofils ist zum Verwaltungszweck jedoch weiterhin möglich."
                severity="error"
              />
            )}
            <CustomField
              name={getGeneralName('taxableInUSA')}
              title="Steuerpflichtig in den USA?"
              type="radiogroup"
              disabled={!isEditing}
              required
            />
            <LabeledGrid label={`Anteile am ${companyOrApplicantCompany}`} required>
              {/* Note: this needs to be a Field because its validations depends on the sideDeal field */}
              <Field
                disabled={isEkOrFreelancer || !isEditing || loadingAllocation}
                checkTouched={false}
                validate={validateShares}
                component={PercentageField}
                onBlur={handleBlurAllocation}
                name={getGeneralName(`shares[${indexOfShare}].allocation`)}
                variant="outlined"
                size="small"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <span>%</span>
                      {getCurrentIcon()}
                    </InputAdornment>
                  ),
                }}
              />
            </LabeledGrid>
            <CustomField
              name={getGeneralName('isBeneficialOwnerLevelOne')}
              required
              title="Wirtschaftlich Berechtigte"
              type="radiogroup"
              disabled={!isEditing || loadingAllocation}
              onChange={handleChangeBO}
              validate={validateBO}
              radioShowNo={
                notEkOrFreelancer &&
                getLocaleAllocation(
                  values.people[refIndex].shares[indexOfShare].allocation,
                  LANGUAGE,
                ) <= 25
              }
              customUpdateFunction={handleUpdateBO}
            />
            {showBOError()}
            {values.people[refIndex].isBeneficialOwnerLevelOne === 'yes' &&
              values.people[refIndex].shares[indexOfShare].allocation &&
              getLocaleAllocation(
                values.people[refIndex].shares[indexOfShare].allocation,
                LANGUAGE,
              ) <= 50 &&
              !noSideDealsDisplayLabel(currentLegalForm?.label) && (
                <CustomField
                  name={`people[${refIndex}].shares[${indexOfShare}].sideDeal`}
                  title="Kann die Person auf vergleichbare Weise Kontrolle ausüben?"
                  tooltip="Sollte die Person/das Unternehmen keine oder zu geringe Anteile am Haupt-unternehmen
            halten, kann dieser/dieses ggf.\n durch Sonderabkommen auf vergleichbare Weise
            Kontrolle ausüben und gilt so als wirtschaftlich Berechtigte(r)."
                  type="radiogroup"
                  disabled={!isEditing || loadingAllocation}
                  onChange={handleChangeSideDeals}
                />
              )}
            <CustomField
              name={getGeneralName('isLegalRepresentative')}
              required
              title="Gesetzliche(r) Vertreter(in)"
              type="radiogroup"
              disabled={!isEditing}
              onChange={handleChangeLP}
              validate={validateLegalRepresentative}
              radioShowNo={notEkOrFreelancer && notGbR && notPartG && notPartGmbB}
            />
            {showLRPError()}
          </>
        );
      case 'company':
        return (
          <>
            <CustomField
              name={getGeneralName('companyName')}
              required
              title="Name des Unternehmens"
              type="text"
              disabled={!isEditing}
            />
            <CustomField
              data={legalFormsArray}
              label="Bitte wählen"
              name={getGeneralName('legalForm')}
              onMap={handlerMap}
              required
              title="Rechtsform"
              type="select"
              disabled={!isEditing}
            />
            <CustomField
              name={getGeneralName('businessAddressStreet')}
              required
              title="Straße Unternehmensanschrift"
              type="text"
              disabled={!isEditing}
            />
            <CustomField
              name={getGeneralName('businessAddressHouseNumber')}
              required
              title="Hausnummer Unternehmensanschrift"
              type="text"
              disabled={!isEditing}
            />
            <CustomField
              name={getGeneralName('businessAddressAdditional')}
              title="Adresszusatz Unternehmensanschrift"
              type="text"
              disabled={!isEditing}
            />
            <CustomField
              name={getGeneralName('businessAddressPostalCode')}
              required
              title="PLZ Unternehmensanschrift"
              type="onlyNumbers"
              disabled={!isEditing}
            />
            <CustomField
              name={getGeneralName('businessAddressCity')}
              required
              title="Ort Unternehmensanschrift"
              type="text"
              disabled={!isEditing}
            />
            <CustomField
              title="Land Unternehmensanschrift"
              name={getGeneralName('businessAddressCountry')}
              textFieldProps={{ label: 'Bitte wählen' }}
              type="place"
              placetype="country"
              disabled={!isEditing}
              required
            />
            <LabeledGrid label="Anteile am Unternehmen" required>
              {/* Note: this needs to be a Field because its validations depends on the sideDeal field */}
              <Field
                disabled={!isEditing || loadingAllocation}
                checkTouched={false}
                validate={validateShares}
                component={PercentageField}
                onBlur={handleBlurAllocation}
                name={getGeneralName(`shares[${indexOfShare}].allocation`)}
                variant="outlined"
                size="small"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <span>%</span>
                      {getCurrentIcon()}
                    </InputAdornment>
                  ),
                }}
              />
            </LabeledGrid>
            <CustomField
              name={getGeneralName('isBeneficialOwnerLevelOne')}
              required
              title="Wirtschaftlich Berechtigte"
              type="radiogroup"
              disabled={!isEditing || loadingAllocation}
              onChange={handleChangeBO}
              customUpdateFunction={handleUpdateBO}
              radioShowNo={
                notEkOrFreelancer &&
                getLocaleAllocation(
                  values.companies[refIndex].shares[indexOfShare].allocation,
                  LANGUAGE,
                ) <= 25
              }
            />
            {showBOError()}
            {values.companies[refIndex].isBeneficialOwnerLevelOne === 'yes' &&
              values.companies[refIndex].shares[indexOfShare].allocation &&
              getLocaleAllocation(
                values.companies[refIndex].shares[indexOfShare].allocation,
                LANGUAGE,
              ) <= 50 &&
              !noSideDealsDisplayLabel(currentLegalForm?.label) && (
                <CustomField
                  name={`companies[${refIndex}].shares[${indexOfShare}].sideDeal`}
                  title="Kann die Person auf vergleichbare Weise Kontrolle ausüben?"
                  tooltip="Sollte die Person/das Unternehmen keine oder zu geringe Anteile am Haupt-unternehmen
            halten, kann dieser/dieses ggf.\n durch Sonderabkommen auf vergleichbare Weise
            Kontrolle ausüben und gilt so als wirtschaftlich Berechtigte(r)."
                  type="radiogroup"
                  disabled={!isEditing || loadingAllocation}
                  onChange={handleChangeSideDeals}
                />
              )}
          </>
        );
      default:
        break;
    }
  }, [
    personOrCompany,
    getGeneralName,
    isEditing,
    values,
    companyOrApplicantCompany,
    isEkOrFreelancer,
    loadingAllocation,
    validateShares,
    handleBlurAllocation,
    indexOfShare,
    getCurrentIcon,
    handleChangeBO,
    validateBO,
    notEkOrFreelancer,
    refIndex,
    handleUpdateBO,
    showBOError,
    currentLegalForm?.label,
    handleChangeSideDeals,
    handleChangeLP,
    validateLegalRepresentative,
    notGbR,
    notPartG,
    notPartGmbB,
    showLRPError,
    legalFormsArray,
    handlerMap,
  ]);

  if (
    !refOwner ||
    (personOrCompany === 'company' && !values.companies[refIndex]) ||
    (personOrCompany === 'person' && !values.people[refIndex])
  ) {
    return null;
  }

  return (
    <Grid item container spacing={4}>
      {values.people && getFields()}
    </Grid>
  );
});

Content.displayName = 'Content';

export default Content;
