import { useContext } from 'react';

import Checkbox from '@mui/material/Checkbox';
import { AuthContext } from '../../context/auth-context';

const CaptiqCheckbox = (props) => {
  const context = useContext(AuthContext);
  return (
    <Checkbox
      inputProps={{ 'data-testid': 'checkbox-input' }}
      {...props}
      style={{ color: context.checkboxColor }}
    />
  );
};

export default CaptiqCheckbox;
