import { FormikValues } from 'formik';
import * as Yup from 'yup';

import { RefBeneficialOwner } from 'context/customer-context/types';
import { ValidateBOResponse } from 'utils/commonTypes/types';
import { Errors } from './index';
import { getValidationErrors } from './getValidationErrors';

export const validateBO = async ({
  ref,
  validationSchema,
  values,
  errors,
  index,
  level,
}: {
  ref: RefBeneficialOwner;
  validationSchema: Yup.ObjectSchema<any>;
  values: FormikValues;
  errors: Errors[];
  index: number;
  level: number;
}): Promise<ValidateBOResponse[]> => {
  const options = { abortEarly: false };
  const result: Promise<ValidateBOResponse[]>[] = ref?.children?.map(async (item, idx) =>
    validateBO({ ref: item, validationSchema, values, errors, index: idx, level: level + 1 }),
  ) as Promise<ValidateBOResponse[]>[];
  let response: ValidateBOResponse | undefined;

  try {
    if (ref._type === 'person') {
      await validationSchema.validate(
        {
          company: values.company,
          people: [values.people[ref.index]],
        },
        options,
      );
    } else {
      await validationSchema.validate(
        {
          company: values.company,
          companies: [values.companies[ref.index]],
        },
        options,
      );
    }
    if (ref.children) {
      response = (await Promise.all(result)) as ValidateBOResponse;
      return [true, response];
    }
    return [true];
  } catch (error: any) {
    errors.push({ position: index, level, errors: getValidationErrors(error) });
    if (ref.children) {
      if (!response) {
        response = (await Promise.all(result)) as ValidateBOResponse;
      }
      return [false, response];
    }
    return [false];
  }
};
