import * as Yup from 'yup';

import { dateOfBirth } from 'utils/validateStaticBlocksValues/dateOfBirth';
import { countryOrNationality } from 'utils/validateStaticBlocksValues/countryOrNationality';

export const countryObject = {
  code: Yup.string().required(),
  country: Yup.string().required(),
  prefix: Yup.string().nullable(),
  nationality: Yup.string().required(),
};

const share = {
  allocation: Yup.string().required().label('Anteile am Unternehmen'),
};

export const validationSchema = Yup.object().shape({
  people: Yup.array().of(
    Yup.lazy((value) =>
      value
        ? Yup.object({
            salutation: Yup.string().required('Bitte wählen Sie eine Anrede').label('Anrede'),
            firstNames: Yup.string()
              .required('Bitte geben Sie Ihren Vornamen ein')
              .label('Vorname(n)'),
            lastName: Yup.string()
              .required('Bitte geben Sie Ihren Nachnamen ein')
              .label('Nachname'),
            title: Yup.string().nullable().label('Titel'),
            dateOfBirth,
            countryOfBirth: countryOrNationality('Geburtsland'),
            cityOfBirth: Yup.string().required().label('Geburtsort'),
            nationality: countryOrNationality('Staatsangehörigkeit'),
            taxableInUSA: Yup.string()
              .oneOf(['no'])
              .required()
              .label('Steuerpflichtig in den USA?'),
            isLegalRepresentative: Yup.string().required().label('Gesetzliche(r) Vertreter(in)'),
            isBeneficialOwner: Yup.string().required().label('Wirtschaftlich Berechtigte'),
            shares: Yup.array().of(Yup.object(share)),
          })
        : Yup.object({}).nullable(),
    ) as unknown as Yup.AnySchema,
  ),
  companies: Yup.array().of(
    Yup.lazy((value) =>
      value
        ? Yup.object({
            companyName: Yup.string()
              .required('Bitte geben Sie den Unternehmensname ein')
              .label('Name des Unternehmens'),
            legalForm: Yup.string()
              .required('Bitte wählen Sie eine Rechtsform')
              .label('Rechtsform'),
            businessAddressStreet: Yup.string().required().label('Straße Unternehmensanschrift'),
            businessAddressHouseNumber: Yup.string()
              .required()
              .label('Hausnummer Unternehmensanschrift'),
            businessAddressAdditional: Yup.string().label('Adresszusatz Unternehmensanschrift'),
            businessAddressPostalCode: Yup.string()
              .max(5, 'Max. 5 Zeichen erlaubt.')
              .required()
              .label('PLZ Unternehmensanschrift'),
            businessAddressCity: Yup.string().required().label('Ort Unternehmensanschrift'),
            businessAddressCountry: countryOrNationality('Land Unternehmensanschrift'),
            isOwner: Yup.string(),
            isBeneficialOwnerLevelOne: Yup.string(),
            isBeneficialOwnerLevelTwo: Yup.string(),
            isBeneficialOwnerLevelThree: Yup.string(),
            isBeneficialOwner: Yup.string(),
            shares: Yup.array().of(
              Yup.object({
                allocation: Yup.string(),
                sideDeal: Yup.string(),
              }),
            ),
          })
        : Yup.object({}).nullable(),
    ) as unknown as Yup.AnySchema,
  ),
});
