import React, { memo, useCallback, useMemo, useState } from 'react';
import { useFormikContext, Field } from 'formik';
import _get from 'lodash.get';

import { Grid, Box, Typography, InputAdornment } from '@mui/material';

import Section from 'components/Section';
import Wrapper from 'components/Wrapper';
import LabeledGrid from 'components/LabeledGrid';
import { Person, RefLegalRepresentative } from 'context/customer-context/types';
import { TITLES, getTitleLabel } from 'constants/titles';
import CustomField from 'components/StaticBlocks/CustomField';
import {
  EKLegalFormLabel,
  FreelanceLegalFormLabel,
  GbRLegalForm,
  PartGLegalForm,
  PartGmbBLegalForm,
} from 'constants/legalForms';
import { FormValues } from 'containers/CustomerProfile/validationSchema';
import {
  addRoleRefs,
  CLOCK_STATUS,
  deleteRoleRefs,
  getCompanyLabel,
  getCurrentIconFunction,
  getLocaleAllocation,
  handleAllocationChangeFunction,
  getTotalShareValueWithParent,
  noSideDealsDisplayLabel,
} from 'utils/utils';
import { YesOrNo, Block, CustomColors, FastFieldProps, Level } from 'utils/commonTypes/types';
import WarningMessage from 'components/WarningMessage';
import { LANGUAGE } from 'constants/language';
import PercentageField from 'components/PercentageField';
import { useLazyEffect } from 'hooks/useLazyEffect';
import { useInterval, validateSharesFunction } from 'utils/dataHandlers';
import { useCustomerData } from 'context/customer-context';
import { SALUTATIONS, getSalutationLabel } from 'constants/salutations';
import { useDataContext } from 'context/data-context';
import { showAgeWarningMessage } from 'components/StaticBlocks/LoanApplicants/components/PersonalData/validations/showAgeWarningMessage';
import { useDynamicTexts } from 'context/dynamic-texts-context';
import { validateBOFunction, validateOwnerFunction } from '../validators';
import { showBOError } from './validations/showBOError';
import { showOwnerError } from './validations/showOwnerError';
import { showLAPError } from './validations/showLAPError';

export interface PersonalDataProps {
  refLegalRepresentative: RefLegalRepresentative;
  formPerson: Person | undefined;
  block: Block;
  customColors: CustomColors;
  isEditing?: boolean;
  isReviewing?: boolean;
  isStartUpTemplate?: boolean;
  showDateOfBirthWarning?: boolean;
}

const PersonalData = memo(
  ({
    refLegalRepresentative,
    formPerson,
    block,
    customColors,
    isEditing = true,
    isReviewing = false,
    isStartUpTemplate = false,
    showDateOfBirthWarning = true,
  }: PersonalDataProps) => {
    const {
      people,
      mainCompany,
      setRefsOwners,
      refsOwners,
      currentLegalForm,
      setRefsBeneficialOwners,
      refsBeneficialOwners,
      legalForms,
      customerProfileId = '',
    } = useCustomerData();
    const { contractSignatoryOrLoanApplicant, companyOrApplicantCompany } = useDynamicTexts();
    const { loanApplicationDuration, loanApplicationUsage } = useDataContext();
    const [loadingAllocation, setLoadingAllocation] = useState(false);
    const [allocationStatus, setAllocationStatus] = useState<string | null>(null);
    const [timerCount, setTimerCount] = useState(0);
    const [showCheck, setShowCheck] = useState(CLOCK_STATUS.STOPPED);
    const { setFieldValue, validateField, values, errors } = useFormikContext<FormValues>();
    const entityShares = formPerson?.shares;

    const sharesIndex = useMemo(() => {
      if (!entityShares) {
        return -1;
      }
      return entityShares.findIndex((v) => v.parent === mainCompany?.id);
    }, [entityShares, mainCompany]);

    const getCurrentIcon = useCallback(
      () => getCurrentIconFunction(loadingAllocation, allocationStatus),
      [loadingAllocation, allocationStatus],
    );

    const share = values.people[refLegalRepresentative.index]?.shares[sharesIndex];

    useInterval(
      () => {
        if (timerCount > 0) {
          setTimerCount(timerCount - 1);
        } else {
          setShowCheck(CLOCK_STATUS.STOPPED);
        }
      },
      showCheck === CLOCK_STATUS.STARTED ? 2000 : null,
    );

    useLazyEffect(() => {
      if (isEditing && values.people[refLegalRepresentative.index]) {
        if (values.people[refLegalRepresentative.index].shares[sharesIndex])
          validateField(
            `people[${refLegalRepresentative.index}].shares[${sharesIndex}].allocation`,
          );
        validateField(`people[${refLegalRepresentative.index}].isOwner`);
        validateField(`people[${refLegalRepresentative.index}].isLoanApplicant`);
        validateField(`people[${refLegalRepresentative.index}].isBeneficialOwnerLevelOne`);
      }
    }, [
      refLegalRepresentative.index,
      validateField,
      people,
      isEditing,
      values.people,
      sharesIndex,
    ]);

    const handleChangeBO = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const { target } = e;
        setFieldValue(
          `people[${refLegalRepresentative.index}].isBeneficialOwnerLevelOne`,
          target.value,
        );
        if (target.value === 'yes') {
          setFieldValue(`people[${refLegalRepresentative.index}].isBeneficialOwner`, 'yes');
          if (
            values.people[refLegalRepresentative.index].shares[sharesIndex].sideDeal === 'yes' &&
            values.people[refLegalRepresentative.index].shares[sharesIndex].allocation
          )
            addRoleRefs(
              'BO',
              refLegalRepresentative.index,
              values.people,
              setRefsBeneficialOwners,
              refsBeneficialOwners,
            );
        }
        if (target.value === 'no') {
          setFieldValue(
            `people[${refLegalRepresentative.index}].shares[${sharesIndex}].sideDeal`,
            'no',
          );
          if (
            (!values.people[refLegalRepresentative.index].isBeneficialOwnerLevelTwo ||
              values.people[refLegalRepresentative.index].isBeneficialOwnerLevelTwo === 'no') &&
            (!values.people[refLegalRepresentative.index].isBeneficialOwnerLevelThree ||
              values.people[refLegalRepresentative.index].isBeneficialOwnerLevelThree === 'no')
          )
            setFieldValue(`people[${refLegalRepresentative.index}].isBeneficialOwner`, 'no');
          const idx = [...refsBeneficialOwners].findIndex(
            (beneficialOwner) => beneficialOwner.id === refLegalRepresentative.id,
          );
          deleteRoleRefs('BO', idx, refsBeneficialOwners, setRefsBeneficialOwners);
          setFieldValue(
            `people[${refLegalRepresentative.index}].shares[${sharesIndex}].sideDeal`,
            'no',
          );
        }
        setTimeout(() => {
          validateField(`people[${refLegalRepresentative.index}].isBeneficialOwnerLevelOne`);
          if (target.value === 'no')
            validateField(
              `people[${refLegalRepresentative.index}].shares[${sharesIndex}].sideDeal`,
            );
        }, 0);
      },
      [
        setFieldValue,
        refLegalRepresentative.index,
        validateField,
        sharesIndex,
        values.people,
        setRefsBeneficialOwners,
        refLegalRepresentative.id,
        refsBeneficialOwners,
      ],
    );

    const handleChangeOwner = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (!value) return;
        if (value === 'yes') {
          addRoleRefs('OW', refLegalRepresentative.index, people, setRefsOwners, refsOwners);
        } else {
          const idx = [...refsOwners].findIndex((owner) => owner.id === refLegalRepresentative.id);
          deleteRoleRefs('OW', idx, refsOwners, setRefsOwners);
        }
        setFieldValue(`people[${refLegalRepresentative.index}].isOwner`, value);
        setTimeout(() => {
          validateField(`people[${refLegalRepresentative.index}].isOwner`);
        }, 0);
      },
      [
        setFieldValue,
        refLegalRepresentative.index,
        refLegalRepresentative.id,
        people,
        setRefsOwners,
        refsOwners,
        validateField,
      ],
    );

    const validateOwner = useCallback(
      (isOwner: YesOrNo) => validateOwnerFunction(isOwner, currentLegalForm),
      [currentLegalForm],
    );

    const validateBO = useCallback(
      (isBeneficialOwner: YesOrNo) => validateBOFunction(isBeneficialOwner, currentLegalForm),
      [currentLegalForm],
    );

    const validateShareFunctionReusable = useCallback(
      (
        allocation: string,
        sideDealDirect?: YesOrNo,
        isBeneficialOwnerLevelOneDirect?: YesOrNo,
        levelDirect?: Level,
        isOwnerDirect?: boolean,
        totalShares?: number,
      ) =>
        validateSharesFunction(
          allocation,
          values.people[refLegalRepresentative.index].shares[sharesIndex].sideDeal,
          values.company.data,
          values.people[refLegalRepresentative.index],
          currentLegalForm,
          legalForms,
          sideDealDirect,
          isBeneficialOwnerLevelOneDirect,
          levelDirect,
          isOwnerDirect,
          true,
          totalShares,
        ),
      [
        values.people,
        values.company.data,
        refLegalRepresentative.index,
        sharesIndex,
        currentLegalForm,
        legalForms,
      ],
    );

    const validateShares = useCallback(
      (allocation: string) => {
        const totalState = getTotalShareValueWithParent(
          mainCompany?.id as number,
          values.people,
          values.companies,
        );
        return validateShareFunctionReusable(
          allocation,
          undefined,
          undefined,
          undefined,
          undefined,
          totalState,
        );
      },
      [values.companies, values.people, validateShareFunctionReusable, mainCompany],
    );

    const handleAllocationChange = useCallback(
      async (allocation: string, sideDeal: YesOrNo) =>
        handleAllocationChangeFunction(
          customerProfileId,
          allocation,
          sideDeal,
          showCheck,
          share.id,
          'person',
          refLegalRepresentative.id,
          values.company.id,
          setLoadingAllocation,
          setShowCheck,
          setAllocationStatus,
        ),
      [share, values.company.id, showCheck, refLegalRepresentative.id, customerProfileId],
    );

    const handleBlurAllocation = useCallback(
      (event: React.FocusEvent<HTMLInputElement>) => {
        const { value } = event.target;
        let currentSideDeal: YesOrNo | undefined =
          values.people[refLegalRepresentative.index].shares[sharesIndex].sideDeal;
        let currentOW: YesOrNo | undefined = values.people[refLegalRepresentative.index].isOwner;
        const currentBO: YesOrNo =
          values.people[refLegalRepresentative.index].isBeneficialOwnerLevelOne;
        const currentAllocation = getLocaleAllocation(value, LANGUAGE);
        const newOwner =
          currentAllocation > 0 &&
          (!values.people[refLegalRepresentative.index].isOwner ||
            values.people[refLegalRepresentative.index].isOwner === 'no');
        const wasOwner =
          currentAllocation === 0 && values.people[refLegalRepresentative.index].isOwner === 'yes';
        const newBO =
          currentAllocation > 25 &&
          values.people[refLegalRepresentative.index].shares[sharesIndex].sideDeal;
        const wasBO =
          currentAllocation <= 25 &&
          values.people[refLegalRepresentative.index].shares[sharesIndex].sideDeal === 'no';
        if (currentAllocation >= 50) {
          setFieldValue(
            `people[${refLegalRepresentative.index}].shares[${sharesIndex}].sideDeal`,
            '',
          );
          currentSideDeal = 'no';
        }
        if (newOwner) {
          addRoleRefs('OW', refLegalRepresentative.index, values.people, setRefsOwners, refsOwners);
          setFieldValue(`people[${refLegalRepresentative.index}].isOwner`, 'yes');
          currentOW = 'yes';
        }
        if (wasOwner) {
          const idx = [...refsOwners].findIndex((owner) => owner.id === refLegalRepresentative.id);
          deleteRoleRefs('OW', idx, refsOwners, setRefsOwners);
          setFieldValue(`people[${refLegalRepresentative.index}].isOwner`, 'no');
          currentOW = 'no';
        }
        if (newBO) {
          const alreadInRefs = refsBeneficialOwners.find((r) => r.id === refLegalRepresentative.id);
          if (!alreadInRefs) {
            addRoleRefs(
              'BO',
              refLegalRepresentative.index,
              values.people,
              setRefsBeneficialOwners,
              refsBeneficialOwners,
            );
            setFieldValue(
              `people[${refLegalRepresentative.index}].isBeneficialOwnerLevelOne`,
              'yes',
            );
            setFieldValue(`people[${refLegalRepresentative.index}].isBeneficialOwner`, 'yes');
          }
        }
        if (wasBO) {
          const indexDeleteBO = [...refsBeneficialOwners].findIndex(
            (beneficialOwner) => beneficialOwner.id === refLegalRepresentative.id,
          );
          deleteRoleRefs('BO', indexDeleteBO, refsBeneficialOwners, setRefsBeneficialOwners);
          if (indexDeleteBO !== -1) {
            setFieldValue(
              `people[${refLegalRepresentative.index}].shares[${sharesIndex}].sideDeal`,
              'no',
            );
            setFieldValue(
              `people[${refLegalRepresentative.index}].isBeneficialOwnerLevelOne`,
              'no',
            );
            if (
              (!values.people[refLegalRepresentative.index].isBeneficialOwnerLevelTwo ||
                values.people[refLegalRepresentative.index].isBeneficialOwnerLevelTwo === 'no') &&
              (!values.people[refLegalRepresentative.index].isBeneficialOwnerLevelThree ||
                values.people[refLegalRepresentative.index].isBeneficialOwnerLevelThree === 'no')
            )
              setFieldValue(`people[${refLegalRepresentative.index}].isBeneficialOwner`, 'no');
          }
        }
        setFieldValue(
          `people[${refLegalRepresentative.index}].shares[${sharesIndex}].allocation`,
          value,
        );
        setTimeout(() => {
          validateField(
            `people[${refLegalRepresentative.index}].shares[${sharesIndex}].allocation`,
          );
          if (newOwner || wasOwner)
            validateField(`people[${refLegalRepresentative.index}].isOwner`);
          if (newBO || wasBO)
            validateField(`people[${refLegalRepresentative.index}].isBeneficialOwnerLevelOne`);
          setTimeout(() => {
            const totalState = getTotalShareValueWithParent(
              mainCompany?.id as number,
              values.people,
              values.companies,
            );
            if (
              !validateShareFunctionReusable(
                value,
                currentSideDeal,
                currentBO,
                1,
                currentOW === 'yes',
                totalState,
              )
            ) {
              if (currentSideDeal) handleAllocationChange(value, currentSideDeal);
            }
          }, 0);
        }, 0);
      },
      [
        setFieldValue,
        sharesIndex,
        validateField,
        values.people,
        values.companies,
        mainCompany,
        refLegalRepresentative,
        refsBeneficialOwners,
        refsOwners,
        setRefsBeneficialOwners,
        setRefsOwners,
        validateShareFunctionReusable,
        handleAllocationChange,
      ],
    );

    const handleChangeSideDeals = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const { target } = e;
        const realAllocation =
          values.people[refLegalRepresentative.index].shares[sharesIndex].allocation;
        const currentAllocation = getLocaleAllocation(realAllocation, LANGUAGE);
        const isBO = target.value === 'yes' && realAllocation;
        const wasBO = target.value === 'no' && realAllocation && currentAllocation <= 25;
        if (!target.value) return;
        if (isBO) {
          const alreadInRefs = refsBeneficialOwners.find((r) => r.id === refLegalRepresentative.id);
          if (!alreadInRefs)
            addRoleRefs(
              'BO',
              refLegalRepresentative.index,
              values.people,
              setRefsBeneficialOwners,
              refsBeneficialOwners,
            );
        } else if (wasBO) {
          const indexDeleteBO = [...refsBeneficialOwners].findIndex(
            (beneficialOwner) => beneficialOwner.id === refLegalRepresentative.id,
          );
          deleteRoleRefs('BO', indexDeleteBO, refsBeneficialOwners, setRefsBeneficialOwners);
        }
        setFieldValue(
          `people[${refLegalRepresentative.index}].shares[${sharesIndex}].sideDeal`,
          target.value,
        );
        setTimeout(() => {
          validateField(`people[${refLegalRepresentative.index}].shares[${sharesIndex}].sideDeal`);
          if (wasBO || isBO)
            validateField(`people[${refLegalRepresentative.index}].isBeneficialOwnerLevelOne`);
          setTimeout(() => {
            const totalState = getTotalShareValueWithParent(
              mainCompany?.id as number,
              values.people,
              values.companies,
            );
            if (
              !validateShareFunctionReusable(
                (values.people[refLegalRepresentative.index].shares[sharesIndex]
                  .allocation as string) || '',
                target.value as YesOrNo,
                undefined,
                undefined,
                undefined,
                totalState,
              )
            ) {
              handleAllocationChange(
                values.people[refLegalRepresentative.index].shares[sharesIndex]
                  .allocation as string,
                target.value as YesOrNo,
              );
            }
          }, 0);
        }, 0);
      },
      [
        setFieldValue,
        refLegalRepresentative.id,
        refLegalRepresentative.index,
        refsBeneficialOwners,
        validateField,
        values.people,
        values.companies,
        mainCompany,
        sharesIndex,
        setRefsBeneficialOwners,
        handleAllocationChange,
        validateShareFunctionReusable,
      ],
    );

    const handleUpdateOWandBO = useCallback(
      (nextProps: FastFieldProps, currentProps: FastFieldProps): boolean => {
        const newFormikValue = nextProps.formik.values;
        const currentFormikValue = currentProps.formik.values;
        const newAllocation = newFormikValue.people;
        const currentAllocation = currentFormikValue.people;
        if (
          getLocaleAllocation(
            newAllocation[refLegalRepresentative.index].shares[sharesIndex].allocation,
            LANGUAGE,
          ) !==
          getLocaleAllocation(
            currentAllocation[refLegalRepresentative.index].shares[sharesIndex].allocation,
            LANGUAGE,
          )
        )
          return true;

        return false;
      },
      [refLegalRepresentative.index, sharesIndex],
    );

    const isEkOrFreelancer = useMemo(
      () =>
        currentLegalForm?.label === EKLegalFormLabel ||
        currentLegalForm?.label === FreelanceLegalFormLabel ||
        currentLegalForm?.short_label === EKLegalFormLabel ||
        currentLegalForm?.short_label === FreelanceLegalFormLabel,
      [currentLegalForm?.label, currentLegalForm?.short_label],
    );

    const showAgeWarning = useMemo(
      () =>
        showDateOfBirthWarning &&
        !!loanApplicationDuration &&
        refLegalRepresentative?.index !== undefined &&
        refLegalRepresentative?.index !== null &&
        _get(values, `people[${refLegalRepresentative.index}].dateOfBirth`),
      [loanApplicationDuration, refLegalRepresentative.index, showDateOfBirthWarning, values],
    );

    return (
      <Grid item xs={12}>
        <Wrapper
          condition={!isReviewing}
          wrapper={(children: JSX.Element) => (
            <Section
              title="PERSONENBEZOGENE DATEN"
              customColors={customColors}
              section_path={block.section_path}
              slug={block.slug}
            >
              {children}
            </Section>
          )}
        >
          <Box m={1}>
            <Grid container spacing={2}>
              <LabeledGrid label="Gesetzlicher Vertreter-ID">
                <Typography>{refLegalRepresentative.legalRepresentativeId}</Typography>
              </LabeledGrid>
              <CustomField
                data={SALUTATIONS}
                getLabel={getSalutationLabel}
                label="Bitte wählen"
                name={`people[${refLegalRepresentative.index}].salutation`}
                title="Anrede"
                type="select"
                disabled={!isEditing}
                required
              />
              <CustomField
                data={TITLES}
                getLabel={getTitleLabel}
                label="Bitte wählen"
                name={`people[${refLegalRepresentative.index}].title`}
                title="Titel"
                type="select"
                disabled={!isEditing}
              />
              <CustomField
                name={`people[${refLegalRepresentative.index}].firstNames`}
                title="Vorname"
                type="text"
                disabled={!isEditing}
                required
              />
              <CustomField
                name={`people[${refLegalRepresentative.index}].lastName`}
                title="Nachname"
                type="text"
                disabled={!isEditing}
                required
              />
              {showAgeWarning
                ? showAgeWarningMessage({
                    birthDate: _get(values, `people[${refLegalRepresentative.index}].dateOfBirth`),
                    firstName: _get(values, `people[${refLegalRepresentative.index}].firstNames`),
                    lastName: _get(values, `people[${refLegalRepresentative.index}].lastName`),
                    loanApplicationUsage,
                    mainCompany,
                    loanApplicationDuration,
                    isStartUpTemplate,
                  })
                : null}
              <CustomField
                name={`people[${refLegalRepresentative.index}].dateOfBirth`}
                title="Geburtsdatum"
                type="date"
                disabled={!isEditing}
                maxDate={new Date()}
                required
              />
              <CustomField
                title="Geburtsland"
                name={`people[${refLegalRepresentative.index}].countryOfBirth`}
                textFieldProps={{ label: 'Bitte wählen' }}
                type="place"
                placetype="country"
                disabled={!isEditing}
                required
              />
              <CustomField
                title="Geburtsort"
                name={`people[${refLegalRepresentative.index}].cityOfBirth`}
                type="text"
                disabled={!isEditing}
                required
              />
              <CustomField
                title="Staatsangehörigkeit"
                name={`people[${refLegalRepresentative.index}].nationality`}
                textFieldProps={{ label: 'Bitte wählen' }}
                type="place"
                placetype="nationality"
                disabled={!isEditing}
                required
              />
              {_get(values, `people[${refLegalRepresentative.index}].taxableInUSA`) === 'yes' && (
                <WarningMessage
                  message="Aufgrund Ihrer Angaben zur Steuerpflicht in den USA kann CAPTIQ Ihnen leider keine Finanzierung anbieten. Das Anlegen eines Kundenprofils ist zum Verwaltungszweck jedoch weiterhin möglich."
                  severity="error"
                />
              )}
              <CustomField
                name={`people[${refLegalRepresentative.index}].taxableInUSA`}
                title="Steuerpflichtig in den USA?"
                type="radiogroup"
                disabled={!isEditing}
                required
              />
              <LabeledGrid
                label={`Anteil an ${getCompanyLabel(values.company.data, legalForms)} (in %)`}
                tooltip="Sollten Sie keine Anteile besitzen, geben Sie bitte 0,00% an. Mit 0,00%. an. Anteilen kann nicht die Rolle Inhaber gewählt werden"
                required
              >
                {/* Note: this needs to be a Field because its validations depends on the sideDeal field */}
                <Field
                  disabled={!isEditing || isEkOrFreelancer || loadingAllocation}
                  checkTouched={false}
                  validate={validateShares}
                  component={PercentageField}
                  onBlur={handleBlurAllocation}
                  name={`people[${refLegalRepresentative.index}].shares[${sharesIndex}].allocation`}
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <span>%</span>
                        {getCurrentIcon()}
                      </InputAdornment>
                    ),
                  }}
                />
              </LabeledGrid>
              <CustomField
                name={`people[${refLegalRepresentative.index}].isOwner`}
                title="Inhaber(in)"
                type="radiogroup"
                tooltip={`Als Inhaber gelten alle Teilhaber am ${companyOrApplicantCompany}.`}
                disabled={!isEditing || loadingAllocation}
                onChange={handleChangeOwner}
                validate={validateOwner}
                radioShowYes={values.people[refLegalRepresentative.index].isOwner === 'yes'}
                radioShowNo={
                  currentLegalForm?.label !== EKLegalFormLabel &&
                  currentLegalForm?.label !== FreelanceLegalFormLabel &&
                  currentLegalForm?.label !== PartGLegalForm &&
                  currentLegalForm?.label !== PartGmbBLegalForm &&
                  currentLegalForm?.label !== GbRLegalForm &&
                  values.people[refLegalRepresentative.index] &&
                  values.people[refLegalRepresentative.index].shares &&
                  values.people[refLegalRepresentative.index].shares[sharesIndex] &&
                  getLocaleAllocation(
                    values.people[refLegalRepresentative.index].shares[sharesIndex].allocation,
                    LANGUAGE,
                  ) === 0 &&
                  values.people[refLegalRepresentative.index].isOwner === 'no'
                }
                customUpdateFunction={handleUpdateOWandBO}
                required
              />
              {showOwnerError({
                errors,
                refLegalRepresentative,
                currentLegalForm,
                values,
              })}
              <CustomField
                name={`people[${refLegalRepresentative.index}].isBeneficialOwnerLevelOne`}
                title="Wirtschaftlich Berechtigte"
                type="radiogroup"
                tooltip="Als wirtschaftlich berechtigt gilt, wer mehr als 25 Prozent der
                Kapitalanteile oder Stimmrechte kontrolliert oder auf vergleichbare Weise
                Kontrolle ausübt"
                disabled={!isEditing || loadingAllocation}
                onChange={handleChangeBO}
                validate={validateBO}
                radioShowNo={
                  currentLegalForm?.label !== EKLegalFormLabel &&
                  currentLegalForm?.label !== FreelanceLegalFormLabel &&
                  values.people[refLegalRepresentative.index].shares[sharesIndex] &&
                  getLocaleAllocation(
                    values.people[refLegalRepresentative.index].shares[sharesIndex]?.allocation,
                    LANGUAGE,
                  ) <= 25
                }
                customUpdateFunction={handleUpdateOWandBO}
                required
              />
              {showBOError({
                errors,
                refLegalRepresentative,
                currentLegalForm,
                values,
              })}
              {values.people[refLegalRepresentative.index].isBeneficialOwnerLevelOne === 'yes' &&
                values.people[refLegalRepresentative.index].shares[sharesIndex].allocation &&
                getLocaleAllocation(
                  values.people[refLegalRepresentative.index].shares[sharesIndex].allocation,
                  LANGUAGE,
                ) <= 50 &&
                !noSideDealsDisplayLabel(currentLegalForm?.label) && (
                  <CustomField
                    name={`people[${refLegalRepresentative.index}].shares[${sharesIndex}].sideDeal`}
                    title="Kann die Person auf vergleichbare Weise Kontrolle ausüben?"
                    tooltip="Sollte die Person/das Unternehmen keine oder zu geringe Anteile am Haupt-unternehmen
              halten, kann dieser/dieses ggf.\n durch Sonderabkommen auf vergleichbare Weise
              Kontrolle ausüben und gilt so als wirtschaftlich Berechtigte(r)."
                    type="radiogroup"
                    disabled={!isEditing || loadingAllocation}
                    onChange={handleChangeSideDeals}
                  />
                )}
              <CustomField
                name={`people[${refLegalRepresentative.index}].isLoanApplicant`}
                title={`${contractSignatoryOrLoanApplicant}(in)`}
                tooltip="Darlehensantragstellende müssen gesetzlich vertretungsberechtigt sein, um den
                Darlehensvertrag schließen zu dürfen."
              >
                {people[refLegalRepresentative.index]?.isLoanApplicant === 'yes' ? (
                  <Typography>Aktiv</Typography>
                ) : (
                  <Typography>Nicht aktiv</Typography>
                )}
              </CustomField>
              {showLAPError({
                errors,
                refLegalRepresentative,
                currentLegalForm,
                values,
              })}
            </Grid>
          </Box>
        </Wrapper>
      </Grid>
    );
  },
);

export default PersonalData;
