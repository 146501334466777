import {
  Person,
  RefLegalRepresentative,
  RefLoanApplicant,
  Company,
  MainCompany,
} from 'context/customer-context/types';
import { BackendCompany, BackendPerson, BackendCustomerProfile } from 'utils/backendTypes';
import { CustomerProfile } from 'context/customer-context/constants';
import { getRolId } from './getRolId';
import { serializeCompanyToContext } from './serializeCompanyToContext';
import { getOwnerRefs } from './getOwnerRefs';
import { getBeneficialOwnerRefs } from './getBeneficialOwnerRefs';
import { serializePersonToContext } from './serializePersonToContext';
import { serializeMainCompany } from './serializeMainCompany';
import { serializeBeneficialOwnersWithLevels } from './serializeBeneficialOwnersWithLevels';

/**
 * Serialize form values into a structure that can be used in customer profile bulk update. All the `values` should be
 * previously validated.
 * @param values: form values from formik.
 */
export const getRefsLegalRepresentatives = (people: Person[]) =>
  people
    .filter((person, index) => {
      // We need to keep the original index of the entity
      // eslint-disable-next-line no-param-reassign
      if (person.isLegalRepresentative === 'yes') person.originalIndex = index;
      return person.isLegalRepresentative === 'yes';
    })
    .map(
      (person): RefLegalRepresentative => ({
        id: person.id,
        legalRepresentativeId: getRolId('LRP', person.correlativeId),
        index: person.originalIndex as number,
        modelId: person.personId,
      }),
    );

export const getRefsLoanApplicants = (people: Person[]) =>
  people
    .filter((person, index) => {
      // We need to keep the original index of the entity
      // eslint-disable-next-line no-param-reassign
      if (person.isLoanApplicant === 'yes') person.originalIndex = index;
      return person.isLoanApplicant === 'yes';
    })
    .map(
      (person): RefLoanApplicant => ({
        id: person.id,
        loanApplicantId: getRolId('LAP', person.correlativeId),
        index: person.originalIndex as number,
        modelId: person.personId,
      }),
    );

export const businessReducer = (
  filtered: Company[],
  option: BackendCompany,
  mainCompany: MainCompany | null,
) => {
  if (!option.customer_profile_relation.roles?.includes('LAC')) {
    filtered.push(serializeCompanyToContext(option, mainCompany));
  }
  return filtered;
};

export const validateOwnerRefs = (
  people: Person[],
  companies: Company[],
  context: MainCompany | null,
) => (context && getOwnerRefs(people, companies)) || [];

export const validateBeneficialOwnerRefs = (
  people: Person[],
  companies: Company[],
  context: MainCompany | null,
) => (context?.id && getBeneficialOwnerRefs(people, companies, context.id)) || [];

export const getPersons = (persons: BackendPerson[], mainCompany: MainCompany | null) =>
  persons.reduce<Person[]>((filter: Person[], person: BackendPerson) => {
    filter.push(serializePersonToContext(person, mainCompany));
    return filter;
  }, []);

export const getCompanies = (companies: BackendCompany[], mainCompany: MainCompany | null) =>
  companies.reduce<Company[]>(
    (filtered: Company[], option: BackendCompany) => businessReducer(filtered, option, mainCompany),
    [],
  );

export const getCustomerProfileData = (
  customerProfile: BackendCustomerProfile,
  mainCompany: MainCompany | null,
) => ({
  people: getPersons(customerProfile.persons, mainCompany),
  companies: getCompanies(customerProfile.businesses, mainCompany),
});

export const getCustomerProfileRefs = (
  people: Person[],
  companies: Company[],
  context: MainCompany | null,
) => ({
  refsLegalRepresentatives: getRefsLegalRepresentatives(people),
  refsLoanApplicants: getRefsLoanApplicants(people),
  refsOwners: validateOwnerRefs(people, companies, context),
  refsBeneficialOwners: validateBeneficialOwnerRefs(people, companies, context),
});

export const getSerializeMainCompany = (mainCompany: BackendCompany | undefined) =>
  (mainCompany && serializeMainCompany(mainCompany)) || null;

export const getCompaniesData = (companies: BackendCompany[]) => {
  const mainCompany = companies.find((v) => v.customer_profile_relation.roles.includes('LAC'));
  let mainCompanyInContext: MainCompany | null = null;
  let mainCompanyFake: number | undefined;
  if (mainCompany?.is_founding) {
    mainCompanyFake = mainCompany?.id;
  } else {
    mainCompanyInContext = getSerializeMainCompany(mainCompany);
  }
  return {
    mainCompanyFake,
    mainCompanyInContext,
  };
};

export const serializeCustomerProfileToContext = (customerProfile: BackendCustomerProfile) => {
  const { mainCompanyInContext } = getCompaniesData(customerProfile.businesses);
  const { people, companies } = getCustomerProfileData(customerProfile, mainCompanyInContext);
  const { refsLegalRepresentatives, refsLoanApplicants, refsOwners, refsBeneficialOwners } =
    getCustomerProfileRefs(people, companies, mainCompanyInContext);

  const customerProfileData: CustomerProfile = {
    id: customerProfile.id,
    customerId: customerProfile.customer_id,
    jurisdiction: customerProfile.cooperation_partner?.id || '',
    customerProfileStatus: customerProfile.customer_profile_status,
    customerProfileType: customerProfile.profile_type,
  };
  const { finalCompanies, finalPeople } = serializeBeneficialOwnersWithLevels(
    companies,
    people,
    refsBeneficialOwners,
  );
  return {
    customerProfileData,
    companies: finalCompanies,
    mainCompany: mainCompanyInContext,
    people: finalPeople,
    refsBeneficialOwners,
    refsLegalRepresentatives,
    refsLoanApplicants,
    refsOwners,
  };
};
