import { BackendPartialPersonForUpdate, BackendPartialShareForUpdate } from 'utils/backendTypes';
import { Person, Share } from 'context/customer-context/types';
import { IN_PLANNING } from 'context/customer-context/constants';
import { LANGUAGE } from 'constants/language';
import { CustomerProfileRole, LegalForm, YesOrNo } from 'utils/commonTypes/types';
import {
  EKLegalForm,
  FreelanceLegalForm,
  GbRLegalFormKey,
  PartGLegalFormKey,
  PartGmbBLegalFormKey,
} from 'constants/legalForms';
import { getLocaleAllocation, getRoleArrayFromPersonCompany } from '../utils';
import { returnBooleanForBackend } from './returnBooleanForBackend';

export const getSerializeSharesPerson = (
  shares: Share[] | undefined,
): BackendPartialShareForUpdate[] => {
  if (shares)
    return shares.map(
      (share) =>
        ({
          allocation: String(getLocaleAllocation(share.allocation, LANGUAGE)),
          parent: share.parent as number,
          side_deal: share.sideDeal ? share.sideDeal === 'yes' : null,
        }) as BackendPartialShareForUpdate,
    );
  return [{ allocation: '0', side_deal: false }];
};

export const getRolesForPerson = (legalForm: string, share?: Share): CustomerProfileRole[] => {
  if (legalForm === GbRLegalFormKey) {
    if (getLocaleAllocation(share?.allocation, LANGUAGE) > 25) return ['OWP', 'BOP', 'LRP', 'LAP'];
    return ['OWP', 'LRP', 'LAP'];
  }
  if (legalForm === FreelanceLegalForm || legalForm === EKLegalForm)
    return ['OWP', 'BOP', 'LRP', 'LAP'];
  if (legalForm === PartGLegalFormKey || legalForm === PartGmbBLegalFormKey) {
    if (
      getLocaleAllocation(share?.allocation, LANGUAGE) > 25 ||
      (getLocaleAllocation(share?.allocation, LANGUAGE) <= 25 && share?.sideDeal === 'yes')
    )
      return ['OWP', 'BOP', 'LRP'];
    return ['OWP', 'LRP'];
  }
  return ['LRP'];
};

export const getSharesForPerson = (
  legalForm: string,
  share?: Share,
): BackendPartialShareForUpdate[] => {
  if (legalForm === GbRLegalFormKey && share) {
    return [
      {
        allocation: `${getLocaleAllocation(share.allocation, LANGUAGE)}`,
        side_deal: false,
      },
    ];
  }
  if (legalForm === FreelanceLegalForm || legalForm === EKLegalForm)
    return [
      {
        allocation: '100',
        side_deal: false,
      },
    ];
  if ((legalForm === PartGLegalFormKey || legalForm === PartGmbBLegalFormKey) && share)
    return [
      {
        allocation: `${getLocaleAllocation(share.allocation, LANGUAGE)}`,
        side_deal: returnBooleanForBackend(share.sideDeal as YesOrNo, true),
      },
    ];
  return [{ allocation: '0', side_deal: false }];
};

export const getIsLegitimacyValue = (isLegitimacy: YesOrNo, legalForm?: LegalForm) => {
  if (
    legalForm &&
    (legalForm === GbRLegalFormKey || legalForm === FreelanceLegalForm || legalForm === EKLegalForm)
  )
    return true;
  return isLegitimacy !== '' ? isLegitimacy === 'yes' : false;
};

export const getBirthDay = (dateOfBirth: Date | '') =>
  dateOfBirth !== '' ? dateOfBirth.toISOString().substring(0, 10) : '';
export const getDateTime = (dateOfBirth: Date | '') =>
  dateOfBirth !== '' ? dateOfBirth.toISOString() : '';

export function serializePersonToBackend(
  person: Person,
  legalForm?: LegalForm,
  mainCompanyStatus?: string,
): BackendPartialPersonForUpdate {
  return {
    id: person.id,
    salutation: person.salutation,
    title: person.title,
    first_name: person.firstNames,
    last_name: person.lastName,
    family_status: person.familySituation,
    birth_name: person.birthName,
    birthday: getBirthDay(person.dateOfBirth) !== '' ? getBirthDay(person.dateOfBirth) : null,
    birth_country: person.countryOfBirth?.code || '',
    birth_city: person.cityOfBirth,
    citizenship: person.nationality?.code || '',
    occupational_group: person.occupationalGroup,
    fatca: person.taxableInUSA ? person.taxableInUSA === 'yes' : null,
    is_sole_representation: person.isSoleRepresentation
      ? person.isSoleRepresentation === 'yes'
      : null,
    children: person.children
      ? person.children
          .filter((child) => child.age !== '')
          .reduce<number[]>((items, v) => {
            items.push(Number(v.age));
            return items;
          }, [])
      : [],
    dependents: person.dependents
      ? person.dependents.map((v) => [String(v.age), String(v.monthlyUpkeep)])
      : [],
    tax_revenue_office: person.taxOffice,
    tax_id: person.taxID,
    tax_nr: person.taxNumber,
    private_address_street: person.personalAddressStreet,
    private_address_postcode: person.personalAddressPostalCode,
    private_address_house_n: person.personalAddressHouseNumber,
    private_address_city: person.personalAddressCity,
    private_address_country: person.personalAddressCountry?.code || '',
    private_address_extra: person.personalAddressAdditional,
    contact_email: person.email,
    contact_phone: person.phoneNumber,
    contact_international_prefix: person.internationalPrefix?.code || '',
    contact_mobile: person.mobilePhone,
    contact_prefix_mobile_phone: person.prefixMobilePhone?.code || '',
    email_contract_signing_sent_date:
      getDateTime(person.emailContractSigningSentDate) !== ''
        ? getDateTime(person.emailContractSigningSentDate)
        : null,
    additional_information: person.additionalInformation,
    customer_profile_relation: {
      roles: legalForm
        ? getRolesForPerson(legalForm, person.shares && person.shares[0])
        : getRoleArrayFromPersonCompany(person),
      legitimacy: getIsLegitimacyValue(person.isLegitimacy, legalForm),
    },
    shares:
      mainCompanyStatus === IN_PLANNING
        ? undefined
        : legalForm
          ? getSharesForPerson(legalForm, person.shares && person.shares[0])
          : getSerializeSharesPerson(person.shares),
  };
}
