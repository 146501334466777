import { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@mui/material';
import Popover from '@mui/material/Popover';

import useStyles from './styles';
import SendComment from './SendComment';

function CaptiqComments(props) {
  const {
    element,
    open,
    comments,
    loanApplication,
    sectionPath,
    onCommentSaved,
    hideInputComment,
  } = props;
  const classes = useStyles();
  let listCommentsRef = null;

  const setListElement = (el) => {
    listCommentsRef = el;
    scrollToBottom();
  };

  const scrollToBottom = useCallback(() => {
    if (listCommentsRef) {
      listCommentsRef.scrollTop = listCommentsRef.scrollHeight;
    }
  }, [listCommentsRef]);

  useEffect(() => {
    scrollToBottom();
  }, [comments, scrollToBottom]);

  return (
    <Popover
      id="pop"
      open={open}
      anchorEl={element}
      onClose={props.onClose}
      anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
      transformOrigin={{ vertical: 'center', horizontal: 'right' }}
    >
      <div className={classes.wrapperComments}>
        <div className={classes.contentComment}>
          <Typography>Interner Kommentar</Typography>
        </div>
        <div className={classes.listComments} ref={setListElement}>
          {comments.map((comment, index) => (
            <div
              key={index}
              className={[
                classes.commentBlock,
                comment.type === 'IC' ? classes.commentBlockPrivate : '',
              ].join(' ')}
            >
              {comment.comment}
              <small>
                {comment.created} by {comment.author}
              </small>
            </div>
          ))}
          {comments.length === 0 && (
            <div className={classes.commentBlock}>No comments for this block.</div>
          )}
        </div>

        {hideInputComment ? null : (
          <div className={classes.contentComment}>
            <SendComment
              loanApplication={loanApplication}
              section={sectionPath}
              onCommentSaved={onCommentSaved}
            />
          </div>
        )}
      </div>
    </Popover>
  );
}

CaptiqComments.defaultPropTypes = {
  onClose: () => {},
};

CaptiqComments.propTypes = {
  onChange: PropTypes.func,
  loanApplication: PropTypes.object,
  onClose: PropTypes.func,
  comments: PropTypes.array,
  element: PropTypes.object,
  open: PropTypes.bool,
  sectionPath: PropTypes.string,
  onCommentSaved: PropTypes.func,
  hideInputComment: PropTypes.bool,
};

export default CaptiqComments;
