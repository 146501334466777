import { useCallback, useState, useContext } from 'react';
import classNames from 'classnames';

import { Tooltip, ClickAwayListener, Zoom, IconButton } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import CloseIcon from '@mui/icons-material/Close';

import HelpIcon from './HelpIcon';
import useStyles from './styles';
import { AuthContext } from '../../context/auth-context';

export const HtmlTooltip = withStyles(() => ({
  tooltip: { background: 'transparent' },
  popper: { pointerEvents: 'auto' },
}))(Tooltip);

const CaptiqTooltip = (props) => {
  const { title, content, customWidth, position, value, hasError, hasValue } = props;
  const classes = useStyles();
  const [display, setDisplay] = useState(false);
  const [locked, setLocked] = useState(false);
  const context = useContext(AuthContext);

  const getIconColor = useCallback(() => {
    if (hasError || !hasValue || !value) {
      return '#00000080';
    }
    return context.mainColor;
  }, [hasError, hasValue, value, context.mainColor]);

  const showOnMouseEnter = useCallback(() => {
    if (!locked) {
      setDisplay(true);
    }
  }, [setDisplay, locked]);

  const hideOnMouseLeave = useCallback(() => {
    if (!locked) {
      setDisplay(false);
    }
  }, [setDisplay, locked]);

  const closeTooltip = useCallback(() => {
    setLocked(false);
    setDisplay(false);
  }, [setDisplay, setLocked]);

  const openTooltip = useCallback(
    (event) => {
      event.preventDefault();
      if (locked && display) {
        closeTooltip();
      } else {
        setLocked(true);
        setDisplay(true);
      }
    },
    [setDisplay, setLocked, display, locked, closeTooltip],
  );

  return (
    <>
      <ClickAwayListener onClickAway={closeTooltip}>
        <HtmlTooltip
          PopperProps={{
            disablePortal: true,
          }}
          placement={position || 'right'}
          open={display}
          TransitionComponent={Zoom}
          classes={{ tooltip: classNames(classes.customWidth, customWidth) }}
          interactive={display}
          title={
            <div className={classes.tooltip}>
              <IconButton
                className={classes.closeButton}
                color="primary"
                aria-label="close"
                component="span"
                onClick={closeTooltip}
                size="large"
              >
                <CloseIcon className={classes.closeButtonIcon} />
              </IconButton>
              {title ? <h4 className={classes.tooltipTitle}>{title}</h4> : null}
              <p>{content}</p>
            </div>
          }
        >
          <span
            onClick={openTooltip}
            onMouseEnter={showOnMouseEnter}
            data-testid="captiq-tooltip-icon"
            onMouseLeave={hideOnMouseLeave}
            className={classNames(classes.helpTextContainer, classes.helpTextIcon)}
          >
            <HelpIcon iconColor={getIconColor()} className={classes.helpIcon} />
          </span>
        </HtmlTooltip>
      </ClickAwayListener>
    </>
  );
};

export default CaptiqTooltip;
